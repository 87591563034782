import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import Header from "../../Header";
import Footer from "../../Footer";
import Loader from "../../../utils/Loader";
import isLoggedIn from "../../../utils/isLoggedIn";
import { alertError } from "../../../utils/alerts";
import { getMyJobApplications, getCurrentUser } from "../../../utils/actions";
import { substrwords } from "../../../utils/strings";
import EmployerLinks from "./components/EmployerLinks";

export default function Applications(props) {
    const [loading, setLoading] = useState(true);
    const [jobs, setAppliedJobs] = useState([]);
    const TOKEN = localStorage.getItem("kracada:token");
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (!isLoggedIn()) {
            props.history.push("/account/login");
            alertError("Please login to continue");
        } else {
        getCurrentUser(TOKEN)
            .then((res) => {
                if(res.data.success){
                    setUser(res.data.data);
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                alertError(err.response.data.error);
            });
        }
    }, [props.history, TOKEN]);

    useEffect(() => {
        if (user && user.first_name && user.email){
            async function getData() {
                await getMyJobApplications()
                .then((res) => {
                    setLoading(false);
                    setAppliedJobs(res.data.data);
                })
                .catch((err) => {
                    setLoading(false);
                    alertError(err.response.data.error);
                });
            }
            getData();
        }
    }, [user]);

    return (
        <div>
            <Header />
            {loading ? (
                <div style={{marginTop: 40}}>
                    <Loader />
                </div>
            ) : (
                <main id="main">
                    <section id="search-result" className="search-result">
                        <div className="container">
                            <div className="row">
                               
                                <div className="col-md-3">
                                    <EmployerLinks accountType={user.accountType && user.accountType} />
                                </div>

                                <div className="col-md-9">
                                    <div className="bg-white trending mt-0" style={{padding: 20}}>
                                        <div className="row">
                                            <div className="col-md-12 form-group square-72">
                                                <h4 style={{fontSize:16, color:'#f60'}} className="font-size-6 font-weight-bold mb-6 text-uppercase">
                                                    <u> My Job Applications</u>
                                                </h4>
                                                {jobs && jobs.length > 0 ?
                                                <div className="row">
                                                    {jobs && jobs.map((job, i) => (
                                                        <div className="col-md-6 col-sm-12 col-xs-12" key={i}>
                                                            <div className="d-md-flex post-entry-2 small-img jobs-card" style={{position:'relative'}}>
                                                                <div>
                                                                    <h3 className="job-title">{job.job_id.job_title} {"  "} 
                                                                            <span className="date">{job.job_id.job_type ? job.job_id.job_type : null} </span>
                                                                    </h3> 
                                                                    <p>
                                                                        {(job.job_id.job_summary && job.job_id.job_summary) ? parse(substrwords(job.job_id.job_summary,100)) : null} 
                                                                    </p>
                                                                    {/* <p>{job?.status === "under_review" ? (
                                                                        <span className="badge badge-danger">{job?.status && job?.status}</span>
                                                                        ) : (
                                                                            (job?.status === "shortlisted") || job?.status === "hired" ? (
                                                                                <span className="badge badge-success">{job?.status && job?.status}</span>
                                                                                ) : null
                                                                        )}
                                                                    </p> */}
                                                                    <span className="date" 
                                                                            style={ job.status === "under_review" ? { 
                                                                                position:'absolute', color:'#fff', bottom:0, right:10, backgroundColor:'#f60'} : { position:'absolute', color:'#fff', bottom:0, right:10, backgroundColor:'#093'}} >
                                                                            {job.status ? job.status : null} 
                                                                        </span> 

                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                : <div className="alert alert-danger mt-4 font-weight-bold">No job application found</div> }
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div> 
                            </div>        
                        </div>
                    </section>
                </main>
            )}
            <Footer />
        </div>
    );
}
