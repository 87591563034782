import React, { useEffect, useState } from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import isLoggedIn from "../../../utils/isLoggedIn";

export default function Started(props) {
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState(null);

    useEffect(() => {
        isLoggedIn() && props.history.push("/account/profile");
    }, [props]);

    const handleChange = (val) => {
        setLoading(true);
        setType(val);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if(type){   
            setLoading(false);
            props.history.push(`/account/register/${type}`);
        }
    };

    return (
        <>
            <Header />
            <main id="main">
                    <section id="contact" className="contact mb-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 text-center mb-5">
                                    <h1 className="page-title" style={{fontSize:30}}>Create an Account</h1>
                                </div>
                            </div>

                            <div className="form" style={{width: 500, marginRight: 'auto', marginLeft: 'auto',}}>
                                <div className="php-email-form">
                                    <div style={{display: "flex", width: '100%', margin:'auto', textAlign:'center', borderBottom: '1px solid #ccc', paddingBottom: 40}}>
                                        <button 
                                            className="register-btn"
                                            onClick={() => handleChange('employer')}
                                        >
                                            I am an Employer
                                        </button>

                                        <button 
                                            className="register-btn"
                                            onClick={() => handleChange('job-seeker')}
                                        >
                                            I am an Job Seeker
                                        </button>
                                    </div>
                                    <div className="text-center mt-4">
                                        <button type="submit"  className="btn btn-primary w-100" 
                                            disabled={!loading ? true : false}
                                            onClick={handleSubmit}
                                            >
                                                Proceed
                                        </button>
                                        <br/>
                                        <p style={{marginTop: 20}}>Already have an account? <a href="/account/login">Login</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>

            <Footer />
        </>
    );
}
