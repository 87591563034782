import React from "react";
// import { substrwords } from "../../../utils/strings";

function AllNews(props) {
    const { business, nigerian, international, technology } = props.data;

    return (
        <>
            <section style={{marginTop: 90, backgroundColor: '#fff'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12" >
                            <div className="mb-4">
                                <div className="section-header justify-content-between align-items-center">
                                    <div><a href="/news/nigerian" style={{ display:"block", float:'right', color: '#fff', backgroundColor:'#f60', borderRadius: 6, fontSize: 15, paddingTop: 0, paddingRight: 14, paddingLeft: 14, paddingBottom: 5 }}>See All</a></div>
                                </div>
                                <div className="section-header d-flex justify-content-between align-items-center">
                                    <h2>Nigerian News</h2>
                                </div>
                            </div>

                            {nigerian && nigerian.length > 0 ? (
                                nigerian && nigerian.map((news_item) => (
                                    <div key={news_item._id}>
                                        <div className="news-card d-block mb-4">
                                            <div className="row">
                                                <div className="col-md-10 col-sm-8 col-12">
                                                    <h2>
                                                        <a href={`news/${news_item.news_category.toLowerCase()}/${news_item._id}`}>
                                                            {news_item.news_title}
                                                        </a>
                                                    </h2>
                                                    {/* <p>{substrwords(news_item.news_details,160)}</p> */}
                                                    <small>{new Date(news_item.createdAt).toDateString()}</small>
                                                </div>
                                                <div className="col-md-2  col-sm-4 col-12">
                                                    <img src={news_item.featuredImg} alt="news" className="img-fluid news-img" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : null}
                        </div>

                        <div className="col-md-4 col-sm-5">
                            <div className="trending">
                                <h3 className="trending-title">Articles</h3>
                                <ul className="trending-post">
                                    <li>
                                        <a href="/articles/gaining-admission-to-ivy-league-universities">
                                            <span className="number">1</span>   
                                            <h3>Top Ten Tips on Gaining Admission to Ivy League Universities</h3>
                                            <span className="author"><i className="fa fa-calendar"></i> January 12th, 2024</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/articles/european-countries-that-do-not-require-ielts">
                                            <span className="number">2</span>
                                            <h3>6 European Countries That Do Not Require IELTS</h3>
                                            <span className="author"><i className="fa fa-calendar"></i> February 9th, 2024</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/articles/universities-that-have-waived-application-fee-for-nigerians">
                                            <span className="number">3</span>
                                            <h3>These 9 Univerities Have Waived Application Fee For Nigerians</h3>
                                            <span className="author"><i className="fa fa-calendar"></i> March 15th, 2024</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/articles/how-to-relocate-with-your-family-to-the-united-kingdom">
                                            <span className="number">4</span>
                                            <h3>9 Tips on How To Relocate With Your Family To The United Kingdom</h3>
                                            <span className="author"><i className="fa fa-calendar"></i> April 12th, 2024</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>

            <section style={{backgroundColor: '#ebecf0'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mb-4">
                            <div className="section-header justify-content-between align-items-center">
                                <div><a href="/news/international" style={{ display:"block", float:'right', color: '#fff', backgroundColor:'#f60', borderRadius: 6, fontSize: 15, paddingTop: 0, paddingRight: 14, paddingLeft: 14, paddingBottom: 5 }}>See All</a></div>
                            </div>
                            <div className="section-header d-flex justify-content-between align-items-center" style={{backgroundColor:'#ebecf0', }}>
                                <h2>International News</h2>
                            </div>
                        </div>

                        {international && international.length > 0 ? (
                            international && international.map((news_item) => (
                                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12" key={news_item._id}>
                                    <div className="news-card d-block mb-4">
                                        <div className="row">
                                            <div className="col-md-10 col-sm-8 col-12">
                                                <h2>
                                                    <a href={`news/${news_item.news_category.toLowerCase()}/${news_item._id}`}>
                                                        {news_item.news_title}
                                                    </a>
                                                </h2>
                                                {/* <p>{substrwords(news_item.news_details,160)}</p> */}
                                                <small>{new Date(news_item.createdAt).toDateString()}</small>
                                            </div>
                                            <div className="col-md-2 col-sm-4 col-12">
                                                <img src={news_item.featuredImg} alt="news" className="img-fluid news-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : null}
                    </div>
                </div>
            </section>

            <section style={{backgroundColor: '#fff'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mb-4">
                            <div className="section-header justify-content-between align-items-center">
                                <div><a href="/news/business" style={{ display:"block", float:'right', color: '#fff', backgroundColor:'#f60', borderRadius: 6, fontSize: 15, paddingTop: 0, paddingRight: 14, paddingLeft: 14, paddingBottom: 5 }}>See All</a></div>
                            </div>
                            <div className="section-header d-flex justify-content-between align-items-center">
                                <h2>Business News</h2>
                            </div>
                        </div>

                        {business && business.length > 0 ? (
                            business && business.map((news_item) => (
                                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12" key={news_item._id}>
                                    <div className="news-card d-block mb-4">
                                        <div className="row">
                                            <div className="col-md-10 col-sm-8 col-12">
                                                <h2>
                                                    <a href={`news/${news_item.news_category.toLowerCase()}/${news_item._id}`}>
                                                        {news_item.news_title}
                                                    </a>
                                                </h2>
                                                {/* <p>{substrwords(news_item.news_details,160)}</p> */}
                                                <small>{new Date(news_item.createdAt).toDateString()}</small>
                                            </div>
                                            <div className="col-md-2 col-sm-4 col-12">
                                                <img src={news_item.featuredImg} alt="news" className="img-fluid news-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : null}
                    </div>
                </div>
            </section>
                
            <section style={{backgroundColor: '#ebecf0'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mb-4">
                            <div className="section-header justify-content-between align-items-center">
                                <div><a href="/news/technology" style={{ display:"block", float:'right', color: '#fff', backgroundColor:'#f60', borderRadius: 6, fontSize: 15, paddingTop: 0, paddingRight: 14, paddingLeft: 14, paddingBottom: 5 }}>See All</a></div>
                            </div>
                            <div className="section-header d-flex justify-content-between align-items-center" style={{backgroundColor:'#ebecf0', }}>
                                <h2>Technology News</h2>
                            </div>
                        </div>

                        {technology && technology.length > 0 ? (
                            technology && technology.map((news_item) => (
                                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12" key={news_item._id}>
                                    <div className="news-card d-block mb-4">
                                        <div className="row">
                                            <div className="col-md-10 col-sm-8 col-12">
                                                <h2>
                                                    <a href={`news/${news_item.news_category.toLowerCase()}/${news_item._id}`}>
                                                        {news_item.news_title}
                                                    </a>
                                                </h2>
                                                {/* <p>{substrwords(news_item.news_details,160)}</p> */}
                                                <small>{new Date(news_item.createdAt).toDateString()}</small>
                                            </div>
                                            <div className="col-md-2 col-sm-4 col-12">
                                                <img src={news_item.featuredImg} alt="news" className="img-fluid news-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : null}
                    </div>
                </div>
            </section>
        </>
    );
}


export default  AllNews;