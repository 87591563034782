import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { substrwords } from "../../../utils/strings";
import Header from "../../Header";
import Footer from "../../Footer";
import Loader from "../../../utils/Loader";
import { getNewsCategory } from "../../../utils/actions";

export default function NewsCategory(props) {
    let { category } = props.match.params;
    category = category.charAt(0).toUpperCase() + category.slice(1);

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    let page = params.page || 1;

    const [loading, setLoading] = useState(true);
    const [news, setData] = useState([]);
    
    useEffect(() => {
        getNewsCategory(category, page)
            .then((res) => {
                setData(res.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err.response);
            });
    }, [category, page]);

    return (
        <div>
            <Header />
                {loading ? (
                    <Loader />
                ) : (
                    <section id="posts" className="posts" style={{marginTop: 90, backgroundColor: '#fff'}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <div className="section-header d-flex justify-content-between align-items-center">
                                        <h2>{category.charAt(0).toUpperCase() + category.slice(1)} News</h2>
                                    </div>
                                </div>

                                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12" >
                                    {news && news.data.length > 0 ? ( 
                                        news.data && news.data.map((news_item) => (
                                            <>
                                            <div key={news_item._id}>
                                                <div className="news-card d-block mb-4">
                                                    <div className="row">
                                                        <div className="col-md-10 col-sm-8 col-12">
                                                            <h2><Link to={{ pathname: `/news/${category}/${news_item._id}`}}>
                                                                {/* <a href={`news/${news_item.news_category.toLowerCase()}/${news_item._id}`}> */}
                                                                    {news_item.news_title}
                                                                {/* </a> */}
                                                                </Link>
                                                            </h2>
                                                            {/* <p>{news_item.news_details ? substrwords(news_item.news_details && news_item.news_details,160) : null}</p> */}
                                                            <small>{new Date(news_item.createdAt).toDateString()}</small>
                                                        </div>
                                                        <div className="col-md-2 col-sm-4 col-12">
                                                            <img src={news_item.featuredImg} alt="news" className="img-fluid news-img" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </>
                                        ))
                                    ) : null}
                                </div>

                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                    <div className="trending">
                                        <h3 className="trending-title">Articles</h3>
                                        <ul className="trending-post">
                                            <li>
                                                <a href="/articles/gaining-admission-to-ivy-league-universities">
                                                    <span className="number">1</span>   
                                                    <h3>Top Ten Tips on Gaining Admission to Ivy League Universities</h3>
                                                    <span className="author"><i className="fa fa-calendar"></i> January 12th, 2024</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/articles/european-countries-that-do-not-require-ielts">
                                                    <span className="number">2</span>
                                                    <h3>6 European Countries That Do Not Require IELTS</h3>
                                                    <span className="author"><i className="fa fa-calendar"></i> February 9th, 2024</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/articles/universities-that-have-waived-application-fee-for-nigerians">
                                                    <span className="number">3</span>
                                                    <h3>These 9 Univerities Have Waived Application Fee For Nigerians</h3>
                                                    <span className="author"><i className="fa fa-calendar"></i> March 15th, 2024</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/articles/how-to-relocate-with-your-family-to-the-united-kingdom">
                                                    <span className="number">4</span>
                                                    <h3>9 Tips on How To Relocate With Your Family To The United Kingdom</h3>
                                                    <span className="author"><i className="fa fa-calendar"></i> April 12th, 2024</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="text-start py-4">
                                        <div className="custom-pagination">
                                            <a href={`?page=${news.prevPage !== null ? news.prevPage : 1}`} className="prev">
                                                Previous
                                            </a>
                                            <a href={`?page=${news.currentPage !== null ? news.currentPage : ""}`} className="active">
                                                {news.currentPage ? news.currentPage : 1}
                                            </a>
                                            <a href={`?page=${news.currentPage !== null ? (news.currentPage + 1) : 4}`}>
                                                {news.currentPage ? (news.currentPage + 1) : 2}
                                            </a>
                                            <a href={`?page=${news.currentPage !== null ? (news.currentPage + 2) : 4}`}>
                                                {news.currentPage ? (news.currentPage + 2) : 3}
                                            </a>
                                            <a href={`?page=${news.currentPage !== null ? (news.currentPage + 3) : 4}`}>
                                                {news.currentPage ? (news.currentPage + 3) : 4}
                                            </a>
                                            <a href={`?page=${news.currentPage !== null ? (news.currentPage + 4) : 5}`}>
                                                {news.currentPage ? (news.currentPage + 4) : 5}
                                            </a>
                                            <a href={`?page=${news.nextPage !== null ? news.nextPage : 6}`} className="next">
                                                Next
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </section>
                )}
            <Footer />
        </div>
    );
}
