import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import Header from "../../Header";
import Footer from "../../Footer";
import Loader from "../../../utils/Loader";
import relocateuk from "../../../assets/img/relocate-uk.jpg";

const INIT_STATE = {
    title:"9 Tips on How To Relocate With Your Family To The United Kingdom",
    content: "<p>Relocating to the United Kingdom with your family can be a thrilling adventure, but it requires careful planning. Here are 9 tips to help make your relocation smooth and successful:</p><p><strong>Research, research, research:</strong> Gather information about the UK's cost of living, culture, and customs to prepare your family for the transition.</p><p><strong>Choose the right visa</strong>: Ensure you have the correct visa for your purpose and duration of stay.</p><p><strong>Find suitable housing:</strong> Research areas and neighborhoods to find a home that suits your family's needs.</p><p><strong>Schooling and education:</strong> Investigate schools and educational options for your children, considering factors like curriculum and distance.</p><p><strong>Healthcare:</strong> Understand the UK's National Health Service (NHS) and how to access healthcare services.</p><p><strong>Finances:</strong> Plan your budget, considering taxes, living costs, and exchange rates.</p><p><strong>Job opportunities:</strong> If applicable, research job prospects and requirements in your field.</p><p><strong>Community and support:</strong> Connect with expat groups and local communities for support and networking.</p><p><strong>Plan for culture shock:</strong> Be prepared for cultural differences and adjustments, and prioritize family bonding and communication during the transition.</p><p>Remember, relocation can be challenging, but with thorough preparation and an open mind, your family can thrive in the UK!</p>",
};

export default function Article4() {
    const [loading, setLoading] = useState(true);
    const [state] = useState(INIT_STATE);
    
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1200);
    }, []); 
    
    return (
        <div>
            <Header />
                {loading ? (
                    <Loader />
                ) : (
                    <section className="single-post-content" style={{marginTop: 90, backgroundColor: '#fff'}}>
                        <div className="container">
                            <div className="row g-5">
                                <div className="col-md-12 post-content mt-4">

                                    <div className="single-post mt-4">
                                        <h1 className="mb-5">{state.title && parse(state.title)} </h1>
                                        
                                        <div className="my-4" style={{backgroundColor: '#999', width:'100%' }}>
                                            <img 
                                                src={relocateuk} 
                                                alt="news" 
                                                className="img-fluid" 
                                                style={{
                                                    width: '100%', height:'100%'
                                                }} 
                                            />
                                        </div>
                                        
                                        <div style={{lineHeight: 1.9, textAlign: 'justify', fontSize: 19}}>
                                            {parse(state.content)}
                                        </div>
                                    </div>
                                </div> 

                            </div>
                        </div>
                    </section>
                )}
            <Footer />
        </div>
    );
}
