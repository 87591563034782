import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import Header from "../../Header";
import Footer from "../../Footer";
import Loader from "../../../utils/Loader";
import isLoggedIn from "../../../utils/isLoggedIn";
import { alertError } from "../../../utils/alerts";
import { getCurrentUser, getJobById } from "../../../utils/actions";
// import { substrwords } from "../../../utils/strings";
import EmployerLinks from "./components/EmployerLinks";
import amountWithCommas from "../../../utils/amountFormmater";
import { ucWords } from "../../../utils/strings";

export default function MyJob(props) {
    const { id } = props.match.params;
    const [loading, setLoading] = useState(true);
    const [job, setJob] = useState([]);
    const TOKEN = localStorage.getItem("kracada:token");
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (!isLoggedIn()) {
            props.history.push("/account/login");
            alertError("Please login to continue");
        } else {
        getCurrentUser(TOKEN)
            .then((res) => {
                setUser(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                alertError(err.response.data.error);
            });
        }
    }, [props.history, TOKEN]);

    useEffect(() => {
        if(user && user._id){
            getJobById(id)
                .then((res) => {
                    setJob(res.data.data);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    console.log(err.response.data);
                })
        }
    }, [user, id]); 
    
    return (
        <div>
            <Header />
            {loading ? (
                <div style={{marginTop: 40}}>
                    <Loader />
                </div>
            ) : (
                <main id="main">
                    <section id="search-result" className="search-result">
                        <div className="container">
                            <div className="row">
                               
                                <div className="col-md-3">
                                    <EmployerLinks accountType={user.accountType && user.accountType} />
                                </div>

                                <div className="col-md-9">
                                    {(user && user.accountType && (user.accountType === "employer") && job) ? <>
                                        <div className="bg-white trending">
                                            <div className="row col-md-12" style={{ margin: "30px 0" }}>
                                                <div className="text-left mb-3">
                                                    <h4 style={{fontSize:16, color:'#f60'}} className="font-size-6 font-weight-bold mb-6 text-uppercase">
                                                        <u> JOB POST: </u>
                                                    </h4>
                                                </div>
                                                <div className="single-post jobs-single">
                                                    <h1 className="mb-4">{job.job_title && job.job_title} </h1>

                                                    {(job.companyName && job.companyName) ? 
                                                        <h3 className="mb-4">COMPANY: {job.companyName && job.companyName}</h3>
                                                    : null}

                                                    {(job.location && job.location) ? 
                                                        <h3 className="mb-4">LOCATION: {ucWords(job.location)}</h3> 
                                                    : null}

                                                    {(job.job_industry && job.job_industry) ? 
                                                        <h3 className="mb-4">INDUSTRY: {ucWords(job.job_industry.toLowerCase().replace(/-/g, " "))}</h3> 
                                                    : null}

                                                    {(job.min_salary && job.min_salary) ? 
                                                        <h3 className="mb-4"><span className="font-weight-bold">SALARY/REMUNERATION:</span> 
                                                            {""} {"NGN"} {amountWithCommas(job.min_salary)} {" - "}{amountWithCommas(job.max_salary)}
                                                        </h3>
                                                    : null}

                                                    {(job.remuneration && job.remuneration) ? 
                                                        <h3 className="mb-4"><span className="font-weight-bold">SALARY/REMUNERATION:</span> 
                                                            {""} {"NGN"} {amountWithCommas(job.remuneration)}
                                                        </h3>
                                                    : null}

                                                    {(job.min_qualification && job.min_qualification) ? 
                                                        <h3 className="mb-4"><span className="font-weight-bold">MINUMUM QUALIFICATION:</span> 
                                                            {" "}{ucWords(job.min_qualification && parse(job.min_qualification))}
                                                        </h3>
                                                    : null}

                                                    {(job.job_summary && job.job_summary) ? <>

                                                        <h3 className="mb-4">Job Summary</h3>

                                                        <p>{parse(job.job_summary && job.job_summary)}</p>
                                                    </> : null}

                                                    {(job.job_requirements && job.job_requirements) ? <>

                                                        <h3 className="mb-4">Job Requirements</h3>

                                                        <p>{parse(job.job_requirements && job.job_requirements)}</p>
                                                    </> : null}

                                                    {(job.job_responsibilities && job.job_responsibilities) ? <>

                                                        <h3 className="mb-4">Job Responsibilities</h3>

                                                        <p>{parse(job.job_responsibilities && job.job_responsibilities)}</p>
                                                    </> : null}

                                                </div>

                                            </div>
                                        </div>
                                    </> : null}
                                </div> 
                            </div>        
                        </div>
                    </section>
                </main>
            )}
            <Footer />
        </div>
    );
}
