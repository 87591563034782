import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import Header from "../../Header";
import Footer from "../../Footer";
import Loader from "../../../utils/Loader";
import isLoggedIn from "../../../utils/isLoggedIn";
import { alertSuccess, alertError } from "../../../utils/alerts";
import { getCurrentUser, updatePersonal } from "../../../utils/actions";
import locations from "../../../utils/states";

import Personal from "./components/Personal";
import Education from "./components/Education";
import Experience from "./components/Experience";
import Skills from "./components/Skills";
import Additional from "./components/Additional";

import EmployerLinks from "./components/EmployerLinks";


export default function UpdateProfile(props) {
    const [activeTab, setActiveTab] = useState("one");
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const TOKEN = localStorage.getItem("kracada:token");

    useEffect(() => {
        if (!isLoggedIn()) {
            props.history.push("/account/login");
            alertError("Please login to continue");
        } else {
        getCurrentUser(TOKEN)
            .then((res) => {
                setUser(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                alertError(err.response.data.error);
            });
        }
    }, [props.history, TOKEN]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            first_name: e.target.first_name.value,
            last_name: e.target.last_name.value,
            email: e.target.email.value,
            phone: e.target.phone.value,
            city: e.target.city.value,
            state: e.target.state.value,
        };
        // setLoading(true);
        updatePersonal(data)
        .then((res) => {
            // setLoading(false);
            alertSuccess(res.data.message);
            window.location.reload();
        })
        .catch((err) => {
            // setLoading(false);
            alertError(err?.response?.data?.error);
        });
    };

    return (
        <div>
            <Header />
            {loading ? (
                <div style={{marginTop: 40}}>
                    <Loader />
                </div>
            ) : (
                <main id="main">
                    <section id="search-result" className="search-result">
                        <div className="container">
                            <div className="row">
                               
                                <div className="col-md-3">
                                    <EmployerLinks accountType={user.accountType && user.accountType} />
                                </div>

                                <div className="col-md-9 col-sm-12">

                                {(user && user.accountType && user.accountType === "employer") ? <>
                                <div className="bg-white trending">
                                    <div className="row col-md-12" style={{ margin: "30px 0" }}>
                                        <div className="text-left">
                                            <h4 style={{fontSize:16, color:'#f60'}} className="font-size-6 font-weight-bold mb-6 text-uppercase">
                                                <u> Update Corporate Information</u>
                                            </h4>
                                        </div>
                                        <form className="col-md-12 padding-small" 
                                            onSubmit={handleSubmit}
                                        >
                                            <div className="form-row">
                                                <div className="col-md-6 form-group">
                                                    <label className="font-weight-bold">First name: <span>*</span></label>
                                                    <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter your first name"
                                                    name="first_name"
                                                    defaultValue={user.first_name}
                                                    />
                                                </div>
                                                <div className="col-md-6 form-group">
                                                    <label className="font-weight-bold">Last name: <span>*</span></label>
                                                    <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter your last name"
                                                    name="last_name"
                                                    defaultValue={user.last_name}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="col-md-6 form-group">
                                                    <label className="font-weight-bold">Email: <span>*</span></label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        placeholder="Enter your email"
                                                        name="email"
                                                        defaultValue={user.email}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-md-6 form-group">
                                                    <label className="font-weight-bold">Phone: <span>*</span></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter your phone"
                                                        name="phone"
                                                        defaultValue={user.phone}
                                                        maxLength={11}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="col-md-6 form-group">
                                                    <label className="font-weight-bold">City: <span>*</span></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter the city you live in"
                                                        name="city"
                                                        defaultValue={user.city}
                                                    />
                                                </div>
                                                <div className="col-md-6 form-group">
                                                    <label className="font-weight-bold">State: <span>*</span></label>
                                                    <select className="form-control" id="state" name="state" defaultValue={user.state}>
                                                    <option value="">Select State...</option>
                                                    {locations.map((location) => (
                                                        <option
                                                            key={location.value}
                                                            defaultValue={location.value}
                                                            selected={user.state === location.value ? "selected" : ""}
                                                        >
                                                            {location.label}
                                                        </option>
                                                    ))}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="form-group mt-3">
                                            {loading ? (
                                                // <button type="button" disabled={true} className="btn btn-dark">
                                                <Loader />
                                                // </button>
                                            ) : (
                                                <button type="submit" className="btn btn-dark btn-block">
                                                Save Changes
                                                </button>
                                            )}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                </> : null}

                                {(user && ((user.accountType === "job-seeker") || (user.accountType === null) || (user.accountType === undefined))) ? <>
                                    {/* <div className="row"> */}
                                    <Tab.Container id="left-tabs-example" className="trending" defaultActiveKey="one">
                                        <div className="bg-white trending">
                                                {/* <div className="border-top mobile-padding border-mercury p-5 pl-xs-12 pt-7 pb-5"> */}
                                                    <Nav
                                                        className="nav d-flex justify-content-start mb-4 border-bottom border-mercury pl-12"
                                                        role="tablist"
                                                    >
                                                        <li className="tab-menu-items">
                                                            <Nav.Link
                                                                onClick={() => setActiveTab("one")}
                                                                eventKey="one"
                                                                className={`text-uppercase cursor-pointer font-size-3 font-weight-bold text-default-color py-3 px-0 ${
                                                                    activeTab === "one" ? "active" : ""
                                                                }`}
                                                            >
                                                            Personal
                                                            </Nav.Link>
                                                        </li>
                                                        <li className="tab-menu-items pr-12">
                                                            <Nav.Link
                                                                onClick={() => setActiveTab("two")}
                                                                eventKey="two"
                                                                className={`text-uppercase cursor-pointer font-size-3 font-weight-bold text-default-color py-3 px-0 ${
                                                                    activeTab === "two" ? "active" : ""
                                                                }`}
                                                            >
                                                            Experience
                                                            </Nav.Link>
                                                        </li>
                                                        <li className="tab-menu-items pr-12">
                                                            <Nav.Link
                                                                onClick={() => setActiveTab("three")}
                                                                eventKey="three"
                                                                className={`text-uppercase cursor-pointer font-size-3 font-weight-bold text-default-color py-3 px-0 ${
                                                                    activeTab === "three" ? "active" : ""
                                                                }`}
                                                            >
                                                            Skills
                                                            </Nav.Link>
                                                        </li>
                                                        <li className="tab-menu-items pr-12">
                                                            <Nav.Link
                                                                onClick={() => setActiveTab("four")}
                                                                eventKey="four"
                                                                className={`text-uppercase cursor-pointer font-size-3 font-weight-bold text-default-color py-3 px-0 ${
                                                                    activeTab === "four" ? "active" : ""
                                                                }`}
                                                            >
                                                            Education
                                                            </Nav.Link>
                                                        </li>
                                                        <li className="tab-menu-items pr-12">
                                                            <Nav.Link
                                                                onClick={() => setActiveTab("five")}
                                                                eventKey="five"
                                                                className={`text-uppercase cursor-pointer font-size-3 font-weight-bold text-default-color py-3 px-0 ${
                                                                    activeTab === "five" ? "active" : ""
                                                                }`}
                                                            >
                                                            Additional
                                                            </Nav.Link>
                                                        </li>
                                                    </Nav>

                                                    {/* <!-- Tab Content --> */}
                                                    <Tab.Content>
                                                        <Tab.Pane eventKey="one">
                                                            <Personal
                                                                locations={locations}
                                                                data={{
                                                                    first_name: user.first_name,
                                                                    last_name: user.last_name,
                                                                    city: user.city,
                                                                    state: user.state,
                                                                    email: user.email,
                                                                    phone: user.phone,
                                                                    date_of_birth: user.date_of_birth,
                                                                    gender: user.gender,
                                                                    profile_picture: user.profile_picture,
                                                                    accountType: (user.accountType && user.accountType) || "",
                                                                }}
                                                            />
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="two">
                                                            <Experience />
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="three">
                                                            <Skills />
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="four">
                                                            <Education />
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="five">
                                                            <Additional
                                                                data={{
                                                                    current_job_title: user.current_job_title,
                                                                    career_summary: user.career_summary,
                                                                    current_net_pay: user.current_net_pay,
                                                                    expected_net_pay: user.expected_net_pay,
                                                                    notice_period: user.notice_period,
                                                                    linkedin: user.linkedin,
                                                                    cv: user.cv,
                                                                }}
                                                            />
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                {/* </div> */}
                                            </div>
                                        </Tab.Container>
                                    {/* </div> */}
                                    </> : null }
                                </div> 
                            </div>        
                        </div>
                    </section>
                </main>
            )}
            <Footer />
        </div>
    );
}
