import React, { useEffect, useState } from "react";
import isLoggedIn from "../../../utils/isLoggedIn";
import { getForgotPasswordCode } from "../../../utils/actions";
import { alertSuccess, alertError } from "../../../utils/alerts";
import Header from "../../Header";
import Footer from "../../Footer";

export default function Forgot(props) {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        isLoggedIn() && props.history.push("/account/profile");
    }, [props]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            email: e.target.email.value,
        };
    
        setLoading(true);
        getForgotPasswordCode(data)
            .then((res) => {
                if(res.data.success){
                    alertSuccess(res.data.message);
                    setLoading(false);
                }
                localStorage.clear();
          })
          .catch((error) => {
                setLoading(false);
                alertError(error.response.data.error);
          });
    };
      
    return (
        <>
            <Header />
                <main id="main">
                    <section id="contact" className="contact mb-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 text-center mb-5">
                                    <h1 className="page-title" style={{fontSize:30}}>Recover Your Password</h1>
                                </div>
                            </div>

                            <div className="form" style={{width: 500, marginRight: 'auto', marginLeft: 'auto',}}>
                                <form onSubmit={handleSubmit} className="php-email-form">
                                    <div className="row">
                                    
                                        <div className="form-group col-md-12">
                                            <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                                        </div>
                                    </div>
                                    <div className="my-3">
                                        <div className="loading">Loading</div>
                                        <div className="error-message"></div>
                                        <div className="sent-message">Your message has been sent. Thank you!</div>
                                    </div>
                                    <div className="text-center">
                                        <button type="submit"  className="btn btn-primary" 
                                            disabled={loading ? true : false}>
                                            {!loading ? "Recover" : <i className="fa fa-spinner fa-spin"></i>}
                                        </button>
                                        <br/>
                                        <p style={{marginTop: 20}}>Have an account? <a href="/account/login">Login</a></p>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </section>

                </main>

            <Footer />
        </>
    );
}
