import React, { useEffect, useState } from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import Loader from "../../../utils/Loader";
import parse from "html-react-parser";
import amountWithCommas from "../../../utils/amountFormmater";
import { ucWords } from "../../../utils/strings";
import { getJobById, applyToJob, getCurrentUser } from "../../../utils/actions";
import { alertSuccess, alertError } from "../../../utils/alerts";
import isLoggedIn from "../../../utils/isLoggedIn";

function JobSingle(props) {
    const { id } = props.match.params;
    const [loading, setLoading] = useState(true);
    const [loadingApply, setLoadingApply] = useState(false);
    const [job, setJob] = useState([]);
    const [user, setUser] = useState(null);
    const TOKEN = localStorage.getItem("kracada:token");

    useEffect(() => {
        if (!isLoggedIn()) {
            props.history.push("/account/login");
            alertError("Please login to continue");
        } else {
        getCurrentUser(TOKEN)
            .then((res) => {
                if(res.data.success){
                    setUser(res.data.data);
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                alertError(err.response.data.error);
            });
        }
    }, [props.history, TOKEN]);

    useEffect(() => {
        getJobById(id)
            .then((res) => {
                setJob(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err.response.data);
            })
    }, [id]); 

    const handleApply = (job_id) => {
        if (!isLoggedIn()) {
            props.history.push("/account/login");
            alertError("Please login to apply.");
        } else {
            if (
                window.confirm(
                    "Note: Your application will be submitted using your current profile. Are you sure you want to continue? Cancel if you want to update your profile before applying, or click OK to continue."
                )
            ) {
                if(user && (user.accountType === "job-seeker")){
                    if(user && (user.skills.length > 0)){
                        if(user && (user.education.length > 0)){
                            if(user && (user.experiences.length > 0)){
                                if(user && (user.certifications.length > 0)){
                                    if(user && (user.phone !== "") && (user.career_summary !=="") && (user.cv !=="") ){
                                        setLoadingApply(true);
                                        applyToJob(job_id)
                                            .then((res) => {
                                                alertSuccess(res.data.message);
                                                setLoadingApply(false);
                                            })
                                            .catch((err) => {
                                                setLoadingApply(false);
                                                alertError(err.response.data.error);
                                            });
                                    } else { alert("Please update your phone number, career summary and CV before applying for jobs!");}
                                } else { alert("Please update your certifications before applying for jobs!");}
                            } else { alert("Please update your experiences before applying for jobs!");}
                        } else { alert("Please update your education history before applying for jobs!");}
                    } else { alert("Please update your skills section before applying for jobs!");}
                } else { alert("You cannot apply for a job with an employer account! Please create a Job Seeker instead!");}
            } else { alert("Canceled!"); }
        }
    };
    
    return (
        <>
        <Header />        
        <section id="search-result" className="single-post-content mt-4">
            <div className="container">
                <div className="row" style={{marginTop: 45}}>
                    {loading ? <Loader /> : 
                    <>
                        
                        <div className="col-md-8 col-sm-7">
                            <div className="single-post jobs-single mt-4">
                                <h1 className="mb-4">{job.job_title && job.job_title} </h1>

                                {(job.companyName && job.companyName) ? 
                                    <h3 className="mb-4">COMPANY: {job.companyName && job.companyName}</h3>
                                : null}

                                {(job.location && job.location) ? 
                                    <h3 className="mb-4">LOCATION: {ucWords(job.location)}</h3> 
                                : null}

                                {(job.job_industry && job.job_industry) ? 
                                    <h3 className="mb-4">INDUSTRY: {ucWords(job.job_industry.toLowerCase().replace(/-/g, " "))}</h3> 
                                 : null}

                                 {(job.min_salary && job.min_salary) ? 
                                    <h3 className="mb-4"><span className="font-weight-bold">SALARY/REMUNERATION:</span> 
                                        {""} {"NGN"} {amountWithCommas(job.min_salary)} {" - "}{amountWithCommas(job.max_salary)}
                                    </h3>
                                 : null}

                                 {((job.remuneration && job.remuneration) && (job.remuneration !== "") && (job.remuneration !=="0")) ? 
                                    <h3 className="mb-4"><span className="font-weight-bold">SALARY/REMUNERATION:</span> 
                                        {""} {"NGN"} {amountWithCommas(job.remuneration)}
                                    </h3>
                                 : null}

                                 {(job.min_qualification && job.min_qualification) ? 
                                    <h3 className="mb-4"><span className="font-weight-bold">MINUMUM QUALIFICATION:</span> 
                                        {" "}{ucWords(job.min_qualification && parse(job.min_qualification))}
                                    </h3>
                                 : null}

                                 {(job.job_summary && job.job_summary) ? <>

                                    <h3 className="mb-4">Job Summary</h3>

                                    <p>{parse(job.job_summary && job.job_summary)}</p>
                                </> : null}

                                 {(job.job_requirements && job.job_requirements) ? <>

                                    <h3 className="mb-4">Job Requirements</h3>

                                    <p>{parse(job.job_requirements && job.job_requirements)}</p>
                                </> : null}

                                {(job.job_responsibilities && job.job_responsibilities) ? <>

                                    <h3 className="mb-4">Job Responsibilities</h3>

                                    <p>{parse(job.job_responsibilities && job.job_responsibilities)}</p>
                                </> : null}


                                {/* <a href="/" className="btn btn-warning">Apply</a> */}
                                <div>
                                    {(job.posted_by && job.posted_by) ? (
                                    <button
                                        // disabled={true}
                                        onClick={() => handleApply(job._id)}
                                        className="btn-apply btn-warning btn-single-apply mt-4"
                                    >
                                        {loadingApply ? ( "<i className='fa fa-spinner fa-spin'></i> Loading") : "Apply" }
                                    </button>
                                    ) : (
                                    <button
                                        onClick={() => handleApply(job._id)}
                                        className="btn-apply btn-warning btn-single-apply mt-4"
                                    >
                                        {loadingApply ? ( <Loader /> ) : "Apply" }
                                    </button>
                                    )}
                                </div>
                            </div>
                        </div> 

                        <div className="col-md-4  col-sm-5 mt-4">
                            <div className="trending">
                                <h3 className="trending-title">Articles</h3>
                                <ul className="trending-post">
                                    <li>
                                        <a href="/articles/gaining-admission-to-ivy-league-universities">
                                            <span className="number">1</span>   
                                            <h3>Top Ten Tips on Gaining Admission to Ivy League Universities</h3>
                                            <span className="author"><i className="fa fa-calendar"></i> January 12th, 2024</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/articles/european-countries-that-do-not-require-ielts">
                                            <span className="number">2</span>
                                            <h3>6 European Countries That Do Not Require IELTS</h3>
                                            <span className="author"><i className="fa fa-calendar"></i> February 9th, 2024</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/articles/universities-that-have-waived-application-fee-for-nigerians">
                                            <span className="number">3</span>
                                            <h3>These 9 Universities Have Waived Application Fee For Nigerians</h3>
                                            <span className="author"><i className="fa fa-calendar"></i> March 15th, 2024</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/articles/how-to-relocate-with-your-family-to-the-united-kingdom">
                                            <span className="number">4</span>
                                            <h3>9 Tips on How To Relocate With Your Family To The United Kingdom</h3>
                                            <span className="author"><i className="fa fa-calendar"></i> April 12th, 2024</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </> }
                </div>        
            </div>
        </section>
        <Footer />
        </>
    );
}

export default JobSingle;