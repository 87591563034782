import React from "react";

function HotelSearch() {
    const categories = [
        { value: "local", label: "Nigerian Restaurant" },
        { value: "chinese", label: "Chinese Restaurant" },
        { value: "intercontinental", label: "Intercontinental Restaurant" },
        { value: "hotel", label: "Hotel" },
        { value: "restaurant", label: "General Restaurant" },
    ];

    return (
        <section className="hero-search">
            <div className="container justify-content-center sort-by">
                <form action="/hotel-search" method="GET" >
                    <div className="row">
                        <div className="col-md-9 col-sm-6 col-12">
                            <div className="bg-white sort-box d-flex justify-content-center mb-2">
                                <select
                                    className="form-control form-select"
                                    name="category"
                                >
                                    {/* <option value="all">All Industries</option> */}
                                    {categories.map((category, i) => ( 
                                        <option value={category.value} key={i}>{category.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="col-md-3 col-sm-6 col-12">
                            <div className="d-flex justify-content-left mb-2">
                                <button type="submit" className="btn btn-block hero-button">Filter</button>
                            </div>

                        </div>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default HotelSearch;