import React, { useState, useEffect} from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import Loader from "../../../utils/Loader";
import HotelSearch from "./HotelSearch";
import { restaurantSearch } from "../../../utils/actions";

export default function SearchHotel() {
    const [loading, setLoading] = useState(true);
    const [items, setData] = useState('');
    const [error, setError] = useState('');
    const [countItems, setCountItems] = useState('');

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
  
    useEffect(() => {
        const data = {
            category: params.category,
        };
        if(data.category){
            restaurantSearch(data)
                .then((res) => {
                    setData(res.data.data)
                    setCountItems(res.data.totalItems)
                    setTimeout(() => {
                        setLoading(false);
                    }, 4500);
                })
                .catch((err) => {
                    setCountItems(0)
                    setError(err.response.data.error)
                    setTimeout(() => {
                        setLoading(false);
                    }, 4500);
                });
        }
    }, [params]);


    return (
        <div>
            <Header />
            <div className="main_hero">
                <HotelSearch />
            </div>
            <section id="search-result" className="search-result" style={{marginTop: 0}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="category-title">Search Result: Found {countItems} items</h3>

                            {loading ? (
                                <Loader />
                            ) : (
                                <>
                                    <div className="row home-restaurant" style={{marginTop: 0, marginBottom:0}}>
                                        {items && items.map((restaurant, i) => (
                                            <div className="col-md-6" key={i}>
                                                <div className="divs">
                                                    <div className="row">
                                                        <div className="col-5">
                                                            <div 
                                                                className="hotel-right" 
                                                                style={{backgroundImage: `url(${restaurant.banner})`}}>
                                                            </div>
                                                        </div>
                                                        <div className="col-7">
                                                            <div className="">
                                                                <h6 className="card-title">{restaurant.restaurant_name}</h6>
                                                                <div className="stars">
                                                                    <i className="bi bi-star"></i>
                                                                    <i className="bi bi-star"></i>
                                                                    <i className="bi bi-star"></i>
                                                                    <i className="bi bi-star"></i>
                                                                    <i className="bi bi-star"></i>
                                                                </div>
                                                                <small>
                                                                    <i className="bi bi-h-square"></i> {restaurant.restaurant_category}
                                                                </small>
                                                                <small>
                                                                    <i className="bi bi-compass"></i> {restaurant.restaurant_address}
                                                                </small>
                                                                <a
                                                                    href={restaurant.restaurant_website ? 
                                                                        restaurant.restaurant_website : 
                                                                        "https://www.kracada.com"
                                                                    }
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                    className="btn btn-warning btn-sm order-btn"
                                                                >
                                                                    View
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        {error && error ? (
                                            <div className="col-md-12">
                                                <div className="alert alert-danger">{error}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                </>
                            )}
                        </div> 
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
}
