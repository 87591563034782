import React from "react";

import bestdish from "../../../assets/img/bestdish.jpg";
import airporthacks from "../../../assets/img/airporthacks.webp";
import jollof from "../../../assets/img/Jollof-Rice.jpg";
import shoes from "../../../assets/img/Wine-Lace-up.jpg";

export default function TipInspiration() {

    return (
        <section className="three-highlights" style={{backgroundColor:'#fefefe'}}>
            <div className="container">
                <div className="row col-lg-12 mx-auto mb-3">
                    <header className="heading-line2 text-center">
                        <h4 className="title-section text-uppercase">Tips and Inspiration</h4>
                    </header>
                </div>

                <div className="row">
                    <div className="col-md-6 col-sm-6">
                        <div className="highlights">
                            <div 
                                className="inspiration-image"
                                style={{
                                    backgroundImage: `url(${jollof})`, 
                                    backgroundPosition:'center'
                                }}>
                                    <div className="full-cover">
                                        <div className="inner-highlight">
                                            <p>
                                                <a href="#/" target="_blank" rel="noreferrer">
                                                    Jollof rice
                                                    <i className="fa fa-caret-right"></i>
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-6">
                        <div className="highlights">
                            <div 
                                className="inspiration-image"
                                style={{
                                    backgroundImage: `url(${shoes})`, 
                                    backgroundPosition:'center'
                                }}>
                                    <div className="full-cover">
                                        <div className="inner-highlight">
                                            <p>
                                                <a href="#/" target="_blank" rel="noreferrer">
                                                    Best Shoes for men
                                                    <i className="fa fa-caret-right"></i>
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>

                    

                    <div className="col-md-6 col-sm-6">
                        <div className="highlights">
                            <div 
                                className="inspiration-image"
                                style={{
                                    backgroundImage: `url(${bestdish})`, 
                                }}>
                                    <div className="full-cover">
                                        <div className="inner-highlight">
                                            <p>
                                                <a href="https://www.tasteatlas.com/best/dishes" target="_blank" rel="noreferrer">
                                                    World's best dishes
                                                    <i className="fa fa-caret-right"></i>
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                            </div>
                        </div>                        
                    </div>

                    <div className="col-md-6 col-sm-6">
                        <div className="highlights">
                            <div 
                                className="inspiration-image"
                                style={{
                                    backgroundImage: `url(${airporthacks})`, 
                                    backgroundPosition:'center'
                                }}>
                                    <div className="full-cover">
                                        <div className="inner-highlight">
                                            <p>
                                                <a href="#/" target="_blank" rel="noreferrer">
                                                    Travel hacks for first time travellers
                                                    <i className="fa fa-caret-right"></i>
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    
                </div> 
            </div>
        </section> 
    );
}
