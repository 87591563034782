import React from "react";
import restaurants from "../../../assets/img/restaurant.jpg";
import jobsImage from "../../../assets/img/post-portrait-7.jpg";
import lifestyle from "../../../assets/img/entertain.webp";
import movies from "../../../assets/img/bg-bookings.png";


export default function FourHighlights() {
    
    return (
        <section className="three-highlights">
            <div className="container">
                <div className="row g-5">
                    <div className="col-md-3 col-sm-12 col-xs-12 col-12">
                        <div className="highlights">
                            <h2>Jobs and Career Development</h2>
                            <div 
                                className="highlight-image"
                                style={{
                                    backgroundImage: `url(${jobsImage})`, 
                                }}>
                            </div>

                            <a href="/jobs">Jobs and Career <i className="fa fa-caret-right"></i></a>
                        </div>
                        
                    </div>

                    <div className="col-md-3 col-sm-12 col-xs-12 col-12">
                        <div className="highlights">
                            <h2>Hotels and Restaurants</h2>
                            <div 
                                className="highlight-image"
                                style={{
                                    backgroundImage: `url(${restaurants})`, 
                                }}>
                            </div>
                            <a href="/hotels-restaurants">Hotels and Restaurants <i className="fa fa-caret-right"></i></a>
                        </div>
                        
                    </div>

                    <div className="col-md-3 col-sm-12 col-xs-12 col-12">
                        <div className="highlights">
                            <h2>Lifestyle and Entertainment</h2>
                            <div 
                                className="highlight-image"
                                style={{
                                    backgroundImage: `url(${lifestyle})`, 
                                }}>
                            </div>
                            <a href="/lifestyle">Lifestyle and Entertainment <i className="fa fa-caret-right"></i></a>
                        </div>
                        
                    </div>

                    <div className="col-md-3 col-sm-12 col-xs-12 col-12">
                        <div className="highlights">
                            <h2>Now Showing Cinema Movies</h2>
                            <div 
                                className="highlight-image"
                                style={{
                                    backgroundImage: `url(${movies})`, 
                                }}>
                            </div>
                            <a href="/movies">Cinema Movies <i className="fa fa-caret-right"></i></a>
                        </div>
                        
                    </div>
                </div> 
            </div>
        </section> 
    );
}
