import React, { useEffect, useState } from "react";
import Loader from "../../../utils/Loader";
// import { getTopSectionNews } from "../../../utils/actions";
import europeschools from "../../../assets/img/europe-schools.png";
import studyineurope from "../../../assets/img/study-in-europe.jpg";
import tuitionfree from "../../../assets/img/tuition-free.jpg";
import relocateuk from "../../../assets/img/relocate-uk.jpg";
// import insurancemoms from "../../../assets/img/insurance-moms.jpg";

export default function ArticlesListing() {
    // const [business, setBusiness] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // getTopSectionNews()
        //     .then((res) => {
        //         setBusiness(res.data.business);
                setTimeout(() => {
                    setLoading(false);
                }, 1200);
            // })
            // .catch((err) => {
            //     console.log(err);
            // })
    }, []); 

    // console.log(business)

    return (
        <main id="main">
            <section className="main_hero">
                <div className="row">
                    <div className="col-md-12">
                        <div className="text-center">
                            <div className="hero_main">
                                <h1 style={{marginBottom: 20, lineHeight:1.3}}>Job search and career transitions can be a pain in the neck.<br /> 
                                Let us help you make it simple!</h1>
                                <p>Find articles and tips that can help you navigate the job market, career transitions, interview preparations and more, both locally and internationally.</p>
                            </div>
                        </div>
                    </div> 
                </div>
            </section> 
            
            <section  id="posts" className="posts">
                <div className="container">
                    <div className="row g-5">

                        {loading ? (
                            <Loader />
                        ) : (
                        <>  
                            <div className="col-md-4 col-sm-12">
                                <a href={`articles/gaining-admission-to-ivy-league-universities`}>
                                    <div className="post-entry-1" style={{backgroundColor: '#f0f0f0', padding: 0, marginBottom: 0}}>
                                        <div className="article_image_box">
                                            <img src={europeschools} alt="news" className="img-fluid" />
                                        </div>
                                        <div className="article_details">
                                            <h4 className="mt-2">Top Ten Tips on Gaining Admission to Ivy League Universities </h4>
                                            <p><i className="fa fa-calendar"></i> January 12th, 2024</p>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <a href={`articles/european-countries-that-do-not-require-ielts`}>
                                    <div className="post-entry-1" style={{backgroundColor: '#f0f0f0', padding: 0, marginBottom: 0}}>
                                        <div className="article_image_box">
                                            <img src={studyineurope} alt="news" className="img-fluid" />
                                        </div>
                                        <div className="article_details">
                                            <h4 className="mt-2">6 European Countries That Do Not Require IELTS </h4>
                                            <p><i className="fa fa-calendar"></i> February 9th, 2024</p>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <a href={`articles/universities-that-have-waived-application-fee-for-nigerians`}>
                                    <div className="post-entry-1" style={{backgroundColor: '#f0f0f0', padding: 0, marginBottom: 0}}>
                                        <div className="article_image_box">
                                            <img src={tuitionfree} alt="news" className="img-fluid" />
                                        </div>
                                        <div className="article_details">
                                            <h4 className="mt-2">These 9 Universities Have Waived Application Fee For Nigerians</h4>
                                            <p><i className="fa fa-calendar"></i> March 15th, 2024</p>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <a href={`articles/how-to-relocate-with-your-family-to-the-united-kingdom`}>
                                    <div className="post-entry-1" style={{backgroundColor: '#f0f0f0', padding: 0, marginBottom: 0}}>
                                        <div className="article_image_box">
                                            <img src={relocateuk} alt="news" className="img-fluid" />
                                        </div>
                                        <div className="article_details">
                                            <h4 className="mt-2">9 Tips on How To Relocate With Your Family To The United Kingdom</h4>
                                            <p><i className="fa fa-calendar"></i> April 12th, 2024</p>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            {/* <div className="col-md-4 col-sm-12">
                                <a href={`job-articles`}>
                                    <div className="post-entry-1" style={{backgroundColor: '#f0f0f0', padding: 0, marginBottom: 0}}>
                                        <div className="article_image_box">
                                            <img src={insurancemoms} alt="news" className="img-fluid" />
                                        </div>
                                        <div className="article_details">
                                            <h4 className="mt-2">Life Insurance And Pregnancy: A Working Mom's Guide</h4>
                                            <p><i className="fa fa-user"></i> Kimberly Ryan</p>
                                        </div>
                                    </div>
                                </a>
                            </div> */}
                        
                        </> )}
                    </div>  

                    {/* <div className="col-md-12">
                        <div className="text-start py-4">
                            <div className="custom-pagination">
                                <a href={`?page=${news.prevPage !== null ? news.prevPage : 1}`} className="prev">
                                    Previous
                                </a>
                                <a href={`?page=${news.currentPage !== null ? news.currentPage : ""}`} className="active">
                                    {news.currentPage ? news.currentPage : 1}
                                </a>
                                <a href={`?page=${news.currentPage !== null ? (news.currentPage + 1) : 4}`}>
                                    {news.currentPage ? (news.currentPage + 1) : 2}
                                </a>
                                <a href={`?page=${news.currentPage !== null ? (news.currentPage + 2) : 4}`}>
                                    {news.currentPage ? (news.currentPage + 2) : 3}
                                </a>
                                <a href={`?page=${news.currentPage !== null ? (news.currentPage + 3) : 4}`}>
                                    {news.currentPage ? (news.currentPage + 3) : 4}
                                </a>
                                <a href={`?page=${news.currentPage !== null ? (news.currentPage + 4) : 5}`}>
                                    {news.currentPage ? (news.currentPage + 4) : 5}
                                </a>
                                <a href={`?page=${news.nextPage !== null ? news.nextPage : 6}`} className="next">
                                    Next
                                </a>
                            </div>
                        </div>
                    </div>   */}

                </div>
            </section>
        </main>
    );
}
