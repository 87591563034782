import React, { useEffect, useState } from "react";
// import parse from "html-react-parser";
import Header from "../../Header";
import Footer from "../../Footer";
import Loader from "../../../utils/Loader";
import isLoggedIn from "../../../utils/isLoggedIn";
import { getCurrentUser, postRestaurant } from "../../../utils/actions";
// import { substrwords } from "../../../utils/strings";
import EmployerLinks from "./components/EmployerLinks";
import { alertSuccess, alertError } from "../../../utils/alerts";


const INIT_STATE = {
    restaurant_name: "",
    restaurant_category:"",
    email:"",
    restaurant_phone_one:"",
    restaurant_address:"",
    restaurant_city:"",
    restaurant_state:"",
    restaurant_about:"",
    restaurant_website:"",
    logo:"",
    banner: "",
    password: "Password123",
};

export default function MyHotelRestaurant(props) {
    const [loading, setLoading] = useState(true);
    // const [jobs, setAppliedJobs] = useState([]);
    const TOKEN = localStorage.getItem("kracada:token");
    const [state, setState] = useState(INIT_STATE);
    const [user, setUser] = useState(null);
    const [banner, setBanner] = useState("");
    const [logo, setLogo] = useState("");

    useEffect(() => {
        if (!isLoggedIn()) {
            props.history.push("/account/login");
            alertError("Please login to continue");
        } else {
        getCurrentUser(TOKEN)
            .then((res) => {
                setUser(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                alertError(err.response.data.error);
            });
        }
    }, [props.history, TOKEN]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = { ...state, banner: banner, logo: logo };
        if(data.logo && data.banner && data.restaurant_about) {
            setLoading(true);
            postRestaurant(data)
                .then((res) => {
                    console.log(res.data)
                    if(res.data.success){
                        alertSuccess(res.data.message);
                    }
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    alertError(err.response.data.error);
                });
        }
    };

    return (
        <div>
            <Header />
            {loading ? (
                <div style={{marginTop: 40}}>
                    <Loader />
                </div>
            ) : (
                <main id="main">
                    <section id="search-result" className="search-result">
                        <div className="container">
                            <div className="row">
                               
                                <div className="col-md-3">
                                    <EmployerLinks accountType={user.accountType && user.accountType} />
                                </div>

                                <div className="col-md-9">
                                    <div className="bg-white trending">
                                        <div className="row col-md-12" style={{ margin: "30px 0" }}>
                                            <div className="text-left mb-3">
                                                <h4 style={{fontSize:16, color:'#f60', float:'right'}} className="font-size-6 font-weight-bold mb-6 text-uppercase">
                                                    <a href="/account/hotels"><u>VIEW YOUR HOTELS</u></a>
                                                </h4>
                                                <h4 style={{fontSize:16, color:'#f60'}} className="font-size-6 font-weight-bold mb-6 text-uppercase">
                                                    <u> MY HOTELS AND RESTAURANTS</u>
                                                </h4>
                                            </div>
                                            <form className="col-md-12 padding-small" 
                                            onSubmit={handleSubmit}
                                            >
                                                <div className="row">
                                                    <div className="col-md-12 form-group square-72">
                                                        <div className="form-row">
                                                            <div className="col-md-6 form-group">
                                                                <label className="font-weight-bold">Name of Hotel / Restaurant: <span>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Name of Hotel / Restaurant"
                                                                    name="restaurant_name"
                                                                    required 
                                                                    onChange={handleChange}
                                                                    defaultValue={state.restaurant_name || ""}
                                                                />
                                                            </div>
                                                            <div className="col-md-6 form-group">
                                                                <label className="font-weight-bold">Business Email  <span>*</span></label>
                                                                <input
                                                                    type="email"
                                                                    className="form-control"
                                                                    placeholder="Business Email"
                                                                    name="email"
                                                                    required 
                                                                    onChange={handleChange}
                                                                    defaultValue={state.email || ""}
                                                                />
                                                            </div>
                                                            <div className="col-md-6 form-group">
                                                                <label className="font-weight-bold">Business Phone  <span>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    maxLength={11}
                                                                    className="form-control"
                                                                    placeholder="Business Phone"
                                                                    name="restaurant_phone_one"
                                                                    required 
                                                                    onChange={handleChange}
                                                                    defaultValue={state.restaurant_phone_one || ""}
                                                                />
                                                            </div>
                                                            <div className="col-md-6 form-group">
                                                                <label className="font-weight-bold">Category <span>*</span></label>
                                                                <select className="form-control" id="restaurant_category" name="restaurant_category" 
                                                                    onChange={handleChange}
                                                                    defaultValue={state.restaurant_category}
                                                                >
                                                                    <option value="">Select Category</option>
                                                                    <option value="local">Nigerian Restaurant</option>
                                                                    <option value="intercontinental">Intercontinental Restaurant</option>
                                                                    <option value="chinese">Chinese Restaurant</option>
                                                                    <option value="hotel">Hotel</option>
                                                                    <option value="restaurant">General Restaurant</option>
                                                                </select>
                                                            </div>
                                                            <div className="col-md-12 form-group">
                                                                <label className="font-weight-bold">About: <span>*</span></label>
                                                                <textarea
                                                                    className="form-control"
                                                                    placeholder="About"
                                                                    rows="2"
                                                                    maxLength={250}
                                                                    name="restaurant_about"
                                                                    defaultValue={state.restaurant_about}
                                                                    onChange={handleChange}
                                                                    required
                                                                ></textarea>
                                                            </div>
                                                            <div className="col-md-6 form-group">
                                                                <label className="font-weight-bold">Address: <span>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Address"
                                                                    name="restaurant_address"
                                                                    required 
                                                                    onChange={handleChange}
                                                                    defaultValue={state.restaurant_address || ""}
                                                                />
                                                            </div>
                                                            <div className="col-md-6 form-group">
                                                                <label className="font-weight-bold">City: <span>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="City"
                                                                    name="restaurant_city"
                                                                    required 
                                                                    onChange={handleChange}
                                                                    defaultValue={state.restaurant_city || ""}
                                                                />
                                                            </div>
                                                            <div className="col-md-6 form-group">
                                                                <label className="font-weight-bold">State: <span>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="State"
                                                                    name="restaurant_state"
                                                                    required 
                                                                    onChange={handleChange}
                                                                    defaultValue={state.restaurant_state || ""}
                                                                />
                                                            </div>
                                                            
                                                            <div className="col-md-6 form-group">
                                                                <label className="font-weight-bold">Website <span>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Website"
                                                                    name="restaurant_website"
                                                                    required
                                                                    onChange={handleChange}
                                                                    defaultValue={state.restaurant_website || ""}
                                                                />
                                                            </div>
                                                            <div className="col-md-6 form-group">
                                                                <label className="font-weight-bold">Banner <span>*</span></label>
                                                                <input
                                                                    type="file"
                                                                    className="form-control"
                                                                    placeholder="Banner"
                                                                    name="banner"
                                                                    required
                                                                    onChange={(e) => {
                                                                        setBanner(e.target.files[0]);
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-md-6 form-group">
                                                                <label className="font-weight-bold">Logo <span>*</span></label>
                                                                <input
                                                                    type="file"
                                                                    className="form-control"
                                                                    placeholder="Logo"
                                                                    name="logo"
                                                                    required
                                                                    onChange={(e) => {
                                                                        setLogo(e.target.files[0]);
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" className="btn btn-dark">Submit</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>                                    
                                </div> 
                            </div>        
                        </div>
                    </section>
                </main>
            )}
            <Footer />
        </div>
    );
}
