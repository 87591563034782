import React from "react";
import Header from "../../Header";
import Footer from "../../Footer";

import HomeTrendingNews from "./TrendingNews";
import FourHighlights from "./FourHighlights";
import HomeJobsHighlight from "./HomeJobsHighlight";
import HotelsHighlight from "./HotelsHighlight";

export default function HomeLanding() {
    return (
        <>
            <Header />
            <HomeTrendingNews />
            <FourHighlights />
            <HotelsHighlight />
            <HomeJobsHighlight />
            <Footer />
        </>
    );
}
