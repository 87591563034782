import React from "react";
import { Carousel } from "react-bootstrap";
import explore from "../../../assets/img/explore.webp";
import music from "../../../assets/img/music.webp";
import beach from "../../../assets/img/beach.jpg";

export default function Highlights() {
    return (
        <section className="tourism-travel-hero" style={{marginTop: 90}}>
            <div className="lifestyle-hero">
                <div
                    className="slider-home-banner slide"
                >
                    <Carousel
                        interval={1000}
                        indicators={false}
                        nextLabel={""}
                        prevLabel={""}
                        touch={true}
                        fade
                    >

                        <Carousel.Item >
                            <a href="/lifestyle">
                                <div 
                                    className="lifestyle-hero-image"
                                    style={{
                                        borderRadius: 0, 
                                        backgroundImage: `url(${music})`, 
                                        backgroundSize: 'cover', 
                                        backgroundPosition: 'center',
                                        margin: '0'
                                    }}>
                                </div>
                                <Carousel.Caption>
                                    <div className="hightitle">
                                        <h5>Music makes the world go round</h5>
                                        <p>Gig tripping in 2024. The beat of a drum. A sweaty dance floor. A festival packed with like-minded people. Live music is everything.</p>
                                    </div>
                                </Carousel.Caption>
                            </a>
                        </Carousel.Item>
                        <Carousel.Item>
                            <a href="/lifestyle">
                                <div 
                                    className="lifestyle-hero-image"
                                    style={{
                                        borderRadius: 0, 
                                        backgroundImage: `url(${explore})`, 
                                        backgroundSize: 'cover', 
                                        backgroundPosition: 'center',
                                        margin: '0'
                                    }}>
                                </div>
                                <Carousel.Caption>
                                    <div className="hightitle">
                                        <h5>Explore the World</h5>
                                        <p>Travel guide, world tour, notable sites to visit.</p>
                                    </div>
                                </Carousel.Caption>
                            </a>
                        </Carousel.Item>

                        <Carousel.Item>
                            <a href="/lifestyle">
                                <div 
                                    className="lifestyle-hero-image"
                                    style={{
                                        borderRadius: 0, 
                                        backgroundImage: `url(${beach})`, 
                                        backgroundSize: 'cover', 
                                        backgroundPosition: 'center',
                                        margin: '0'
                                    }}>
                                </div>
                                <Carousel.Caption>
                                    <div className="hightitle">
                                        <h5>Fashion trends?</h5>
                                        <p>If you’re looking to find the latest fashion trends in May 2024 look no further...</p>
                                    </div>
                                </Carousel.Caption>
                            </a>
                        </Carousel.Item>
                    </Carousel>
                </div>
            </div>
        </section> 
    );
}
