import React, { useEffect, useState } from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import { userSignup } from "../../../utils/actions";
import isLoggedIn from "../../../utils/isLoggedIn";
import { alertSuccess, alertError } from "../../../utils/alerts";

export default function Register(props) {
    const [passwordError, setPasswordError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const { type } = props.match.params;

    useEffect(() => {
        isLoggedIn() && props.history.push("/account/profile");
    }, [props]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if(type !== "" || type !== undefined){
            const data = {
                first_name: e.target.first_name.value,
                last_name: e.target.last_name.value,
                email: e.target.email.value,
                password: e.target.password.value,
                type: type
            };
            
            if (data.password.length < 6) {
                setPasswordError(true);
                return;
            }
            
            if(data.type && data.email && data.first_name && data.last_name && data.password){
                setLoading(true);
                userSignup(data)
                .then((res) => {
                    alertSuccess(res.data.message);
                    setLoading(false);
                    props.history.push("/account/login");
                })
                .catch((error) => {
                    alertError(error.response.data.error);
                    setLoading(false);
                });
                setTimeout(() => {
                    setLoading(false)
                }, 4500);
            };
        }
    }
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    return (
        <>
            <Header />
            <main id="main">
                    <section id="contact" className="contact mb-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 text-center mb-5">
                                    <h1 className="page-title" style={{fontSize:30}}>Create {type.charAt(0).toUpperCase() + type.slice(1).replace('-', ' ')} Account</h1>
                                </div>
                            </div>

                            <div className="form" style={{width: 500, marginRight: 'auto', marginLeft: 'auto',}}>
                                <form onSubmit={handleSubmit} className="php-email-form">
                                    <div className="row">
                                        <div className="form-group col-md-12">
                                            <input type="text" name="type" className="form-control" id="type" placeholder="Type" required 
                                            defaultValue={type} disabled style={{textTransform:'capitalize'}}/>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <input type="text" name="first_name" className="form-control" id="fname" placeholder="First Name" required />
                                        </div>
                                        <div className="form-group col-md-12">
                                            <input type="text" name="last_name" className="form-control" id="lname" placeholder="Last Name" required />
                                        </div>
                                        <div className="form-group col-md-12">
                                            <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                                        </div>
                                        <div className="form-group col-md-12">
                                            <div className="pass-wrapper">
                                                <input 
                                                    type={passwordShown ? "text" : "password"} 
                                                    name="password" 
                                                    className="form-control" 
                                                    id="password" 
                                                    placeholder="Your Password" 
                                                    required 
                                                />
                                                <span onClick={togglePasswordVisiblity}>
                                                    {passwordShown ? (
                                                        <i className="fas fa-eye-slash" aria-hidden="true"></i>
                                                    ) : (
                                                        <i className="fas fa-eye" aria-hidden="true"></i>
                                                    )}
                                                </span>
                                                {passwordError && (
                                                    <small className="text-danger">
                                                        Password must be at least 6 characters
                                                    </small>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="my-3">
                                        <div className="loading">Loading</div>
                                        <div className="error-message"></div>
                                        <div className="sent-message">Your message has been sent. Thank you!</div>
                                    </div>
                                    <div className="text-center">
                                        <button type="submit"  className="btn btn-primary" 
                                            disabled={loading ? true : false}>
                                            {!loading ? "Create Account" : <i className="fa fa-spinner fa-spin"></i>}
                                        </button>
                                        <br/>
                                        <p style={{marginTop: 20}}>Already have an account? <a href="/account/login">Login</a></p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                </main>

            <Footer />
        </>
    );
}
