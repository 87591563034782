import React from "react";

export default function EmployerLinks({accountType}) {
    return (
        <>
        {((accountType === "job-seeker") || (accountType === undefined) || (accountType === null)) ? <>
            <div className="trending">
                <h3>Account Profile</h3>
                <ul className="trending-post">
                    <li>
                        <a href="/account/profile">
                            <span className="number">1</span>   
                            <h3>My Profile</h3>
                        </a>
                    </li>
                    <li>
                        <a href="/account/update-profile">
                            <span className="number">2</span>   
                            <h3>Update My Profile</h3>
                        </a>
                    </li>
                    <li>
                        <a href="/account/applied-jobs">
                            <span className="number">3</span>   
                            <h3>My Job Applications</h3>
                        </a>
                    </li>
                    <li>
                        <a href="/account/cv-review">
                            <span className="number">4</span>   
                            <h3>CV Review</h3>
                        </a>
                    </li>
                    <li>
                        <a href="/account/hotels-restaurants">
                            <span className="number">5</span>   
                            <h3>My Restaurants</h3>
                        </a>
                    </li>
                    <li>
                        <a href="/#" onClick={() => {
                            localStorage.clear();
                            window.location.reload();
                            }}
                            style={{display: "block"}}
                        >
                            <span className="number">6</span>   
                            <h3>Logout</h3>
                        </a>
                    </li>
                </ul>
            </div>
           </> : (
                <div className="trending">
                    <h3>Employer Account</h3>
                    <ul className="trending-post">
                        <li>
                            <a href="/account/profile">
                                <span className="number">1</span>   
                                <h3>My Profile</h3>
                            </a>
                        </li>
                        <li>
                            <a href="/account/update-profile">
                                <span className="number">2</span>   
                                <h3>Update My Profile</h3>
                            </a>
                        </li>
                        <li>
                            <a href="/account/job-ad">
                                <span className="number">3</span>   
                                <h3>Post Job Ad</h3>
                            </a>
                        </li>
                        <li>
                            <a href="/account/myjobs">
                                <span className="number">4</span>   
                                <h3>My Jobs</h3>
                            </a>
                        </li>
                        <li>
                            <a href="/account/hotels-restaurants">
                                <span className="number">5</span>   
                                <h3>My Restaurants</h3>
                            </a>
                        </li>
                        <li>
                            <a href="/#" onClick={() => {
                                localStorage.clear();
                                window.location.reload();
                                }}
                                style={{display: "block"}}
                            >
                                <span className="number">6</span>   
                                <h3>Logout</h3>
                            </a>
                        </li>
                    </ul>
                </div>
            )}
        
        </>
    );
}
