import React, { useState } from "react";
import Loader from "../../../../utils/Loader";
import { alertError, alertSuccess } from "../../../../utils/alerts";
import { updatePersonal } from "../../../../utils/actions";

const genders = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];

export default function Personal(props) {
  const user = props.data;
  const locations = props.locations;
  const [loading, setLoading] = useState(false);
  const [picture, setProfilePicture] = useState("");
  const [picture_preview, setImage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      first_name: e.target.first_name.value,
      last_name: e.target.last_name.value,
      email: e.target.email.value,
      phone: e.target.phone.value,
      city: e.target.city.value,
      state: e.target.state.value,
      gender: e.target.gender.value,
      date_of_birth: e.target.date_of_birth.value,
      profile_picture: picture,
    };
    setLoading(true);
    updatePersonal(data)
      .then((res) => {
        setLoading(false);
        alertSuccess(res.data.message);
      })
      .catch((err) => {
        setLoading(false);
        alertError(err.response.data.error);
      });
  };

  return (
    <div className="row col-md-12" style={{ margin: "30px 0" }}>
      <div className="text-left">
        <h4 style={{fontSize:16, color:'#f60'}} className="font-size-6 font-weight-bold mb-6 text-uppercase">
            <u> Update Personal Information</u>
        </h4>
      </div>
      <form className="col-md-12 padding-small" onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="col-md-6 form-group">
            <label className="font-weight-bold">First name: <span>*</span></label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter your first name"
              name="first_name"
              defaultValue={user.first_name}
            />
          </div>
          <div className="col-md-6 form-group">
            <label className="font-weight-bold">Last name: <span>*</span></label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter your last name"
              name="last_name"
              defaultValue={user.last_name}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="col-md-6 form-group">
            <label className="font-weight-bold">Email: <span>*</span></label>
            <input
              type="email"
              className="form-control"
              placeholder="Enter your email"
              name="email"
              defaultValue={user.email}
              disabled
            />
          </div>
          <div className="col-md-6 form-group">
            <label className="font-weight-bold">Contact Phone: <span>*</span></label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter your contact phone"
              name="phone"
              defaultValue={user.phone}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="col-md-6 form-group">
            <label className="font-weight-bold">Resident City: <span>*</span></label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter the city you live in"
              name="city"
              defaultValue={user.city}
            />
          </div>
          <div className="col-md-6 form-group">
            <label className="font-weight-bold">Resident State: <span>*</span></label>
            <select className="form-control" id="state" name="state" defaultValue={user.state} >
              <option value="">Select State...</option>
              {locations.map((location) => (
                <option
                  key={location.value}
                  defaultValue={location.value}
                  // selected={user.state === location.value ? "selected" : ""}
                >
                  {location.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6 form-group">
            <label className="font-weight-bold">Gender: <span>*</span></label>
            <select className="form-control" id="gender" name="gender" defaultValue={user.gender} >
              <option value="">Select Gender...</option>
              {genders.map((gender) => (
                <option
                  key={gender.value}
                  defaultValue={gender.value}
                  // selected={user.gender === gender.value ? "selected" : ""}
                >
                  {gender.label}
                </option>
              ))}
            </select>
          </div>
        
          <div className="col-md-6 form-group">
            <label htmlFor="date_of_birth" className="font-size-4 font-weight-bold text-black-2 line-height-reset">
              Date of Birth: <span>*</span>
            </label>

            <input
              type="text"
              className="form-control bg-light text-dark"
              id="date_of_birth"
              onFocus={(e) => {
                e.currentTarget.type = "date";
                e.currentTarget.focus();
              }}
              placeholder={new Date(user.date_of_birth).toLocaleDateString()}
              name="date_of_birth"
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-12 form-group square-72">
            <label
              htmlFor="profile_picture"
              className="font-size-4 font-weight-bold text-black-2 line-height-reset"
            >
              Upload profile picture: <span>*</span>
            </label> <br/>
            {user.profile_picture && !picture_preview ? (
              <img className="img-fluid mb-2" src={user.profile_picture} alt=" " />
            ) : (
              <img className="img-fluid mb-2" src={picture_preview} alt=" " />
            )}

            <input
              type="file"
              className="form-control"
              name="profile_picture"
              id="profile-photo"
              onChange={(e) => {
                setProfilePicture(e.target.files[0]);
                setImage(URL.createObjectURL(e.target.files[0]));
              }}
            />
            
          </div>
        </div>

        <div className="form-group">
          {loading ? (
            // <button type="button" disabled={true} className="btn btn-dark">
              <Loader />
            // </button>
          ) : (
            <button type="submit" className="btn btn-dark btn-block">
              Save Changes
            </button>
          )}
        </div>
      </form>
    </div>
  );
}
