import React from "react";

function CareerHero() {
    return (
        <section style={{marginTop: 50, padding:0}}>
            <div className="row">
                <div className="col-md-12">
                    <div className="text-center">
                        <div className="hero_top">
                            <h1>Grow your career!<br/>Discover amazing opportunities!</h1>
                            <p>
                                Tips on evaluating your skills, assessing your career needs, and becoming a competitive candidate in the global market.
                            </p>
                        </div>
                    </div>
                </div> 
            </div>
        </section> 
    );
}

export default  CareerHero;