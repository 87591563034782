import React, { useState, useEffect} from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import parse from "html-react-parser";
import Loader from "../../../utils/Loader";
import { substrwords } from "../../../utils/strings";
import HeroSearch from "./HeroSearch";
import { jobsSearch } from "../../../utils/actions";

export default function Landing() {
    const [loading, setLoading] = useState(true);
    const [jobs, setJobs] = useState('');
    const [error, setError] = useState('');
    const [countJobs, setCountJobs] = useState('');

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
  
    useEffect(() => {
        const data = {
            industry: params.industry,
            location: params.location,
            experience: params.experience,
        };

        if(data.industry && data.experience && data.location){
            jobsSearch(data)
                .then((res) => {
                    setJobs(res.data.jobs)
                    setCountJobs(res.data.totalJobs)
                    setLoading(false);
                })
                .catch((err) => {
                    setCountJobs(0)
                    setError(err.response.data.error)
                    setLoading(false);
                });
        }
    }, [params]);

    return (
        <div>
            <Header />
            <div className="main_hero">
                <HeroSearch />
            </div>
            <section id="search-result" className="search-result" style={{marginTop: 0}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="category-title">Job Search Result: Found {countJobs} jobs</h3>

                            {loading ? (
                                <Loader />
                            ) : (
                                <>
                                    <div className="row">
                                        {jobs && jobs.map((job, i) => (
                                            <div className="col-md-4 col-sm-4 col-xs-12" key={i}>
                                                <a href={`jobs/${job._id}`}>
                                                    <div className="d-md-flex post-entry-2 small-img jobs-card">
                                                        <div>
                                                            <h3 className="job-title" style={{marginBottom:0}}>{job.job_title} {"  "} 
                                                                <span className="date" style={{textTransform:'uppercase'}}>{job.location ? job.location : null} </span> 
                                                            </h3>
                                                            <div className="post-meta" style={{marginBottom:0 }}>
                                                                <span style={{fontWeight:'bold' }} >Industry:</span> <span>{job.job_industry} </span>
                                                            </div>
                                                            <p>{jobs.job_summary ? parse(substrwords(jobs.job_summary,100)) : null} </p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        ))}

                                        {error && error ? (
                                            <div className="col-md-12">
                                                <div className="alert alert-danger">{error}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                </>
                            )}
                        </div> 
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
}
