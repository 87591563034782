import React, { useEffect, useState } from "react";
import isLoggedIn from "../../../utils/isLoggedIn";
import { resetPassword, getResetUser } from "../../../utils/actions";
import { alertSuccess, alertError } from "../../../utils/alerts";
import Header from "../../Header";
import Footer from "../../Footer";

export default function Reset(props) {
    const [user, setUser] = useState(false);
    const [loading, setLoading] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordShownConfirm, setPasswordShownConfirm] = useState(false);
    const { id } = props.match.params;

    useEffect(() => {
        isLoggedIn() && props.history.push("/account/profile");
    }, [props]);

    useEffect(() => {
        if(id){
            const data = {
                code: id
            }
            getResetUser(data)
                .then((res) => {
                    setUser(res.data.data);
                })
                .catch((err) => {
                    alertError(err.response.data.error);
                })
        }
    }, [id]); 


    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            confirm_password: e.target.confirm_password.value,
            password: e.target.password.value,
            code: id ? id : null
        };
        
        if(data.confirm_password && data.password && data.code && user.email){
            if(data.confirm_password === data.password){
                setLoading(true);
                resetPassword(data)
                .then((res) => {
                    if(res.data.success){
                        alertSuccess(res.data.message);
                        setLoading(false);
                        props.history.push("/account/login");
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    alertError(error.response.data.error);
                });
            }else {
                alertError("Password and Confirm Password do not match.");
            }
        }else {
            alertError("Password and Confirm Password are required.");
        }
    };

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    const togglePasswordVisiblityConfirm = () => {
        setPasswordShownConfirm(passwordShownConfirm ? false : true);
    };
      
    return (
        <>
            <Header />
                <main id="main">
                    <section id="contact" className="contact mb-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 text-center mb-5">
                                    <h1 className="page-title" style={{fontSize:30}}>Reset Password</h1>
                                </div>
                            </div>

                            <div className="form" style={{width: 500, marginRight: 'auto', marginLeft: 'auto',}}>
                                <form onSubmit={handleSubmit} className="php-email-form">
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <input type="text" name="first_name" className="form-control" id="fname" placeholder="First Name" 
                                            defaultValue={user.first_name} disabled />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <input type="text" name="last_name" className="form-control" id="lname" placeholder="Last Name" 
                                            defaultValue={user.last_name} disabled />
                                        </div>
                                        <div className="form-group col-md-12">
                                            <input type="text" name="email" className="form-control" id="email" placeholder="Email" 
                                            defaultValue={user.email} disabled />
                                        </div>
                                        <div className="form-group col-md-12">
                                            <div className="pass-wrapper">
                                                <input 
                                                    type={passwordShown ? "text" : "password"} 
                                                    name="password" 
                                                    className="form-control" 
                                                    id="password" 
                                                    placeholder="Your Password" 
                                                    required 
                                                />
                                                <span onClick={togglePasswordVisiblity}>
                                                    {passwordShown ? (
                                                        <i className="fas fa-eye-slash" aria-hidden="true"></i>
                                                    ) : (
                                                        <i className="fas fa-eye" aria-hidden="true"></i>
                                                    )}
                                                </span>
                                            </div>                                            
                                        </div>
                                        <div className="form-group col-md-12">
                                            <div className="pass-wrapper">
                                                <input 
                                                    type={passwordShownConfirm ? "text" : "password"} 
                                                    name="confirm_password" 
                                                    className="form-control" 
                                                    id="confirm_password" 
                                                    placeholder="Confirm Your Password" 
                                                    required 
                                                />
                                                <span onClick={togglePasswordVisiblityConfirm}>
                                                    {passwordShownConfirm ? (
                                                        <i className="fas fa-eye-slash" aria-hidden="true"></i>
                                                    ) : (
                                                        <i className="fas fa-eye" aria-hidden="true"></i>
                                                    )}
                                                </span>
                                            </div>                                            
                                        </div>
                                    </div>
                                    <div className="my-3">
                                        <div className="loading">Loading</div>
                                        <div className="error-message"></div>
                                        <div className="sent-message">Your message has been sent. Thank you!</div>
                                    </div>
                                    <div className="text-center">
                                        <button type="submit"  className="btn btn-primary" 
                                            disabled={loading ? true : false}>
                                            {!loading ? "Submit" : <i className="fa fa-spinner fa-spin"></i>}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                </main>
            <Footer />
        </>
    );
}
