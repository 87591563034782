import React, { useEffect, useState } from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import Loader from "../../../utils/Loader";
import isLoggedIn from "../../../utils/isLoggedIn";
import { alertError, alertSuccess } from "../../../utils/alerts";
import { getCurrentUser, cvReviewSubmit, getCVreviewChats } from "../../../utils/actions";
import EmployerLinks from "./components/EmployerLinks";
import parse from "html-react-parser";

export default function CVReview(props) {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const TOKEN = localStorage.getItem("kracada:token");
    const [cv_review, setCV] = useState("");
    const [chats, setChats] = useState(null);

    useEffect(() => {
        if (!isLoggedIn()) {
            props.history.push("/account/login");
            alertError("Please login to continue");
        } else {
        getCurrentUser(TOKEN)
            .then((res) => {
                setUser(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                alertError(err?.response?.data.error);
            });
        }
    }, [props.history, TOKEN]);

    useEffect(() => {
        getCVreviewChats()
            .then((res) => {
                setChats(res.data.data);
            })
            .catch((err) => {
                console.log(err.response.data.error);
            });
    }, []);
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            cv_review_desc: e.target.cv_review_desc.value,
            cv_plan: e.target.cv_plan.value,
            cv_review: cv_review,
        };

        if(data.cv_plan && data.cv_review && data.cv_review_desc){
            if(user && (user.skills.length > 0) && (user.education.length > 0) && (user.experiences.length > 0) && 
                (user.certifications.length > 0) && (user.phone !== "") && (user.career_summary !=="") && 
                (user.cv !=="")){
                if(user && user.accountType !== undefined && user.accountType === "job-seeker"){
                    setLoading(true);
                    cvReviewSubmit(data)
                    .then((res) => {
                        alertSuccess(res.data.message);
                        getCurrentUser(TOKEN)
                        .then((res) => {
                            setUser(res.data.data);
                            setLoading(false);
                        })
                        .catch((err) => {
                            setLoading(false);
                        });
                    })
                    .catch((err) => {
                        setLoading(false);
                        alertError(err.response.data.error);
                    });
                } else {alert("You cannot use an employer account to request CV review!");}
            } else { alert("Please update your profile before requesting CV review!"); }
        } else { alert("Please fill all fields!"); }
    };

    return (
        <div>
            <Header />
            {loading ? (
                <div style={{marginTop: 40}}>
                    <Loader />
                </div>
            ) : (
                <main id="main">
                    <section id="search-result" className="search-result">
                        <div className="container">
                            <div className="row">
                               
                                <div className="col-md-3">
                                    <EmployerLinks accountType={user && user.accountType && user.accountType} />
                                </div>

                                <div className="col-md-9 col-sm-12">
                                    <section  style={{borderColor:'#555', backgroundColor:'#A9A9A9', color:'#fff', paddingTop:20, paddingBottom:0, paddingRight:0, paddingLeft:0}}>
                                        <div className="container">
                                            <div className="row mb-4 text-center">
                                                <div className="col-md-3 col-sm-6  col-3">
                                                    <div className="small-cv-circle">
                                                        <i className="bi bi-check-circle fa-3x" style={{color:'#093'}}></i>
                                                    </div>
                                                    <h2 style={{fontSize: 16}}>Create account</h2>
                                                </div>

                                                <div className="col-md-3 col-sm-6  col-3">
                                                    <div className="small-cv-circle">
                                                        <i className="bi bi-file-text-fill fa-3x" style={(user && user.cv_review) ? {color:'#093'} : {color:"#fff"}}></i>
                                                    </div>
                                                    <h2 style={{fontSize: 16}}>Submit Your CV</h2>
                                                </div>

                                                <div className="col-md-3 col-sm-6 col-3">
                                                    <div className="small-cv-circle">
                                                        <i className="bi bi-credit-card fa-3x" style={{color:'#093'}}></i>
                                                    </div>
                                                    <h2 style={{fontSize: 16}}>Make Payment</h2>
                                                </div> 

                                                <div className="col-md-3 col-sm-6 col-3">
                                                    <div className="small-cv-circle">
                                                        <i className="bi bi-film fa-3x" style={{color:'#fff'}}></i>
                                                    </div>
                                                    <h2 style={{fontSize: 16}}>CV Review</h2>
                                                </div> 
                                            </div> 
                                        </div>
                                    </section>

                                    <div className="bg-white trending mt-4" style={{padding: 20}}>
                                        {user && (!user.cv_review && !user.cv_review_desc) ? 
                                        <form className="col-md-12 padding-small" onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-md-12 form-group square-72">
                                                    <h4 style={{fontSize:16, color:'#f60'}} className="font-size-6 font-weight-bold mb-6 text-uppercase">
                                                        <u> Upload Your CV for Review</u>
                                                    </h4>
                                                    
                                                    <div className="form-row">
                                                        <div className="col-md-12 form-group">
                                                            <label className="font-weight-bold">Select Plan <span>*</span></label>
                                                            <select className="form-control" id="cv_plan" name="cv_plan" required>
                                                                <option value="">Select...</option>
                                                                <option value="Deluxe">Deluxe</option>
                                                                <option value="Supreme">Supreme</option>
                                                                <option value="Premium">Premium</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="form-row">
                                                        <div className="col-md-12 form-group">
                                                            <label className="font-weight-bold">Upload CV for Review: <span>*</span></label>
                                                            <input
                                                                type="file"
                                                                className="form-control"
                                                                name="cv_review"
                                                                id="cv_review"
                                                                required
                                                                onChange={(e) => setCV(e.target.files[0])}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="form-row">
                                                        <div className="col-md-12 form-group">
                                                            <label className="font-weight-bold">CV description: <span>*</span></label>
                                                            <textarea
                                                                type="text"
                                                                className="form-control"
                                                                cols={33}
                                                                rows={5}
                                                                placeholder="Enter some description"
                                                                name="cv_review_desc"
                                                                required
                                                            >
                                                            </textarea>
                                                        </div>
                                                    </div>

                                                </div>
                                                <button type="submit" className="btn btn-dark">Upload</button>
                                            </div>
                                        </form> : 
                                        <>
                                            <div className="alert alert-success">Your CV has been submitted for review successfully.</div>
                                            {(chats && chats.length > 0) ? 
                                                <>
                                                    <div className="card">
                                                        <div className="card-header p-3 font-weight-bold" 
                                                            style={{fontSize: 20}}>
                                                            Please find below our review of your CV:
                                                        </div>
                                                        {chats && chats.map((chat, i) => (
                                                        <div className="card-body">
                                                            <div className="alert alert-primary mb-0" style={{borderRadius:0}}>
                                                                <p>{chat.chat && parse(chat.chat)}</p>
                                                                <small>{chat.createdAt && new Date(chat.createdAt).toDateString()}</small>
                                                            </div>
                                                        </div>
                                                        ))}

                                                    </div>
                                                </> : null}
                                            </>
                                        }
                                    </div>
                                </div> 
                            </div>        
                        </div>
                    </section>
                </main>
            )}
            <Footer />
        </div>
    );
}
