import React from "react";
import logo from "../assets/img/logo.png";

function Header() {

    return (
        <>
            <header id="header" className="header d-flex align-items-center fixed-top">
                <div className="container-fluid container-xl d-flex align-items-center justify-content-between">

                    <a href="/" className="d-flex align-items-center">
                        <img className="logo" src={logo} alt="logo" style={{height: 75}} width={200} />
                    </a>

                    <nav id="navbar" className="navbar">
                        <ul>
                            <li className="dropdown"><a href="/jobs"><span>Jobs</span> <i className="bi bi-chevron-down dropdown-indicator"></i></a>
                                <ul>
                                    <li><a href="/jobs">Job Opportunities</a></li>
                                    <li><a href="/cv-review">Review Your CV</a></li>
                                    {/* <li><a href="/job-articles">Job Search Articles</a></li> */}
                                    {/* <li><a href="/companies">Company Listing</a></li> */}
                                </ul>
                            </li>
                            {/* <li className="dropdown"><a href="/career"><span>Career</span> <i className="bi bi-chevron-down dropdown-indicator"></i></a>
                                <ul>
                                    <li><a href="/career">Career Planning</a></li>
                                    <li><a href="/oversea-education">Oversea Education</a></li>
                                    <li><a href="/work-life">Work and Life</a></li>
                                </ul>
                            </li> */}
                            <li><a href="/news"><span>News</span></a></li>
                            {/* <li className="dropdown"><a href="/news"><span>News</span> <i className="bi bi-chevron-down dropdown-indicator"></i></a>
                                <ul>
                                    <li><a href="/news">All News</a></li>
                                    <li><a href="/news/nigerian">Nigerian News</a></li>
                                    <li><a href="/news/international">International News</a></li>
                                    <li><a href="/news/business">Business News</a></li>
                                    <li><a href="/news/technology">Technology News</a></li>
                                    <li><a href="/news/sports">Sports News</a></li>
                                </ul>
                            </li> */}
                            <li><a href="/lifestyle"><span>Lifestyle</span></a></li>
                            {/* <li className="dropdown"><a href="/lifestyle"><span>Lifestyle</span> <i className="bi bi-chevron-down dropdown-indicator"></i></a>
                                <ul>
                                    <li><a href="/lifestyle">Lifestyle</a></li>
                                    <li><a href="/movies">Movies</a></li>
                                </ul>
                            </li> */}
                            <li><a href="/hotels-restaurants"><span>Hotels and Restaurants</span></a></li>
                            <li><a href="/travel-tourism"><span>Travel and Tourism</span></a></li>
                        </ul>
                    </nav>

                    <div className="position-relative">
                        <i className="bi bi-list mobile-nav-toggle"></i>
                    </div>

                </div>

            </header>
        </>
    );
}

export default Header;
