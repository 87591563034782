import { BrowserRouter as Router, Route } from "react-router-dom";
// components
import HomeLanding from "./components/sections/home/HomeLanding";
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
import PrivacyPolicy from "./components/PrivacyPolicy";

// auth
import Login from "./components/sections/auth/Login";
import Register from "./components/sections/auth/Register";
import Started from "./components/sections/auth/Started";
import Forgot from "./components/sections/auth/Forgot";
import Reset from "./components/sections/auth/Reset";

// account
import Profile from "./components/sections/account/Profile";
import Applications from "./components/sections/account/Applications";
import UpdateProfile from "./components/sections/account/UpdateProfile";
import CVreview from "./components/sections/account/CVreview";
import JobAd from "./components/sections/account/JobAd";
import MyJobs from "./components/sections/account/MyJobs";
import MyJob from "./components/sections/account/MyJob";
import MyHotelRestaurant from "./components/sections/account/MyHotelRestaurant";

// news
import NewsLanding from "./components/sections/news/NewsLanding";
import NewsCategory from "./components/sections/news/NewsCategory";
import NewsSingle from "./components/sections/news/NewsSingle";

// jobs
import JobsLanding from "./components/sections/jobs/JobsLanding";
import CompanyListing from "./components/sections/jobs/CompanyListing";
import JobSingle from "./components/sections/jobs/JobSingle";
import CvReview from "./components/sections/jobs/CVReview";
import SearchJobs from "./components/sections/jobs/SearchJobs";

// articles
import ArticlesLanding from "./components/sections/articles/ArticlesLanding";
import Article1 from "./components/sections/articles/Article1";
import Article2 from "./components/sections/articles/Article2";
import Article3 from "./components/sections/articles/Article3";
import Article4 from "./components/sections/articles/Article4";

// careers
import CareerLanding from "./components/sections/career/CareerLanding";
import OverseaEducation from "./components/sections/career/OverseaEducation";
import WorkLife from "./components/sections/career/WorkLife";

// lifestyle
import LifestyleLanding from "./components/sections/lifestyle/LifestyleLanding";
// import Entertainment from "./components/sections/lifestyle/Entertainment";

// movies
import FilmHouseLanding from "./components/sections/movies/Landing";

// hotels and restaurants
import Hotels from "./components/sections/hotels/Hotels";

// travel and tourism
import TravelTourism from "./components/sections/travels/TravelTourism";
import Tourism from "./components/sections/travels/Tourism";

import "./App.css";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-multi-carousel/lib/styles.css";
import MyHotels from "./components/sections/account/MyHotels";
import SearchHotel from "./components/sections/hotels/SearchHotel";

function App() {
  return (
    <Router>
      <Route exact path={"/"} component={HomeLanding}></Route>
      <Route exact path={"/news"} component={NewsLanding}></Route>
      <Route exact path={"/news/:category"} component={NewsCategory}></Route>
      <Route exact path={"/news/:category/:id"} component={NewsSingle}></Route>
      
      <Route exact path={"/articles"} component={ArticlesLanding}></Route>
      <Route exact path={"/articles/gaining-admission-to-ivy-league-universities"} component={Article1}></Route>
      <Route exact path={"/articles/european-countries-that-do-not-require-ielts"} component={Article2}></Route>
      <Route exact path={"/articles/universities-that-have-waived-application-fee-for-nigerians"} component={Article3}></Route>
      <Route exact path={"/articles/how-to-relocate-with-your-family-to-the-united-kingdom"} component={Article4}></Route>

      
      
      <Route exact path={"/jobs"} component={JobsLanding}></Route>
      <Route exact path={"/jobs/:id"} component={JobSingle}></Route>
      <Route exact path={"/cv-review"} component={CvReview}></Route>
      <Route exact path={"/companies"} component={CompanyListing}></Route>
      <Route exact path={"/jobs-search"} component={SearchJobs}></Route>

      <Route exact path={"/lifestyle"} component={LifestyleLanding}></Route>
      <Route exact path={"/movies"} component={FilmHouseLanding}></Route>
      
      <Route exact path={"/hotels-restaurants"} component={Hotels}></Route>
      <Route exact path={"/hotel-search"} component={SearchHotel}></Route>

      <Route exact path={"/travel-tourism"} component={TravelTourism}></Route>
      <Route exact path={"/tourism/:id"} component={Tourism}></Route>

      <Route exact path={"/career"} component={CareerLanding}></Route>
      <Route exact path={"/oversea-education"} component={OverseaEducation}></Route>
      <Route exact path={"/work-life"} component={WorkLife}></Route>
      
      <Route exact path={"/about-us"} component={AboutUs}></Route>
      <Route exact path={"/contact-us"} component={ContactUs}></Route>
      <Route exact path={"/privacy-policy"} component={PrivacyPolicy}></Route>

      <Route exact path={"/account/login"} component={Login}></Route> 
      <Route exact path={"/account/register"} component={Started}></Route>
      <Route exact path={"/account/register/:type"} component={Register}></Route>
      <Route exact path={"/account/forgot"} component={Forgot}></Route>
      <Route exact path={"/account/reset-password/:id"} component={Reset}></Route>
      <Route exact path={"/account/profile"} component={Profile}></Route>
      <Route exact path={"/account/applied-jobs"} component={Applications}></Route>
      <Route exact path={"/account/update-profile"} component={UpdateProfile}></Route>
      <Route exact path={"/account/cv-review"} component={CVreview}></Route>
      <Route exact path={"/account/job-ad"} component={JobAd}></Route>
      <Route exact path={"/account/myjobs"} component={MyJobs}></Route>
      <Route exact path={"/account/myjob/:id"} component={MyJob}></Route>
      <Route exact path={"/account/hotels-restaurants"} component={MyHotelRestaurant}></Route>
      <Route exact path={"/account/hotels"} component={MyHotels}></Route>

      
    </Router>
  );
}

export default App;
