import React, { useEffect, useState } from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import Loader from "../../../utils/Loader";
import { getRestaurants } from "../../../utils/actions";

export default function Hotels() {
    const [loading, setLoading] = useState(true);
    const [restaurants, setRestaurants] = useState([]);

    useEffect(() => {
        getRestaurants('local')
            .then((res) => {
                setRestaurants(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err.response.data);
            })
    }, []); 

    const categories = [
        { value: "local", label: "Nigerian Restaurant" },
        { value: "chinese", label: "Chinese Restaurant" },
        { value: "intercontinental", label: "Intercontinental Restaurant" },
        { value: "hotel", label: "Hotel" },
        { value: "restaurant", label: "General Restaurant" },
    ];

    return (
        <div>
            <Header />
            <section className="entertainment_hero">
                <div className="row">
                    <div className="col-md-12">
                        <div className="text-center">
                            <div className="hero_main">
                                <h1>Find the right hotel or restaurant today <br /></h1>

                                


                                {/* <div className="search_form_box">
                                    <div className="form-group btn-group">
                                        <input 
                                            type="search" 
                                            placeholder="Enter keyword" 
                                            className="form-control" 
                                            name="keyword" 
                                            required 
                                        />
                                        <button className="btn">Search</button>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div> 
                </div>
            </section>

            <section  className="home-restaurant" style={{}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h3 className="category-title">All Hotels and Restaurants</h3>

                            <div className="row">

                                {loading ? (
                                    <Loader />
                                ) : (
                                    <>
                                    {restaurants && restaurants.map((restaurant, i) => (
                                        <div className="col-md-6" key={i}>
                                            <div className="divs">
                                                <div className="row">
                                                    <div className="col-5">
                                                        <div 
                                                            className="hotel-right" 
                                                            style={{backgroundImage: `url(${restaurant.banner})`}}>
                                                        </div>
                                                    </div>
                                                    <div className="col-7">
                                                        <div className="">
                                                            <h6 className="card-title">{restaurant.restaurant_name}</h6>
                                                            <div className="stars">
                                                                <i className="bi bi-star"></i>
                                                                <i className="bi bi-star"></i>
                                                                <i className="bi bi-star"></i>
                                                                <i className="bi bi-star"></i>
                                                                <i className="bi bi-star"></i>
                                                            </div>
                                                            <small>
                                                                <i className="bi bi-h-square"></i> {restaurant.restaurant_category}
                                                            </small>
                                                            <small>
                                                                <i className="bi bi-compass"></i> {restaurant.restaurant_address}
                                                            </small>
                                                            <a
                                                                href={restaurant.restaurant_website ? 
                                                                    restaurant.restaurant_website : 
                                                                    "https://www.kracada.com"
                                                                }
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                className="btn btn-warning btn-sm order-btn"
                                                            >
                                                                View
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    </> )}
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="aside-block">
                                <h3  style={{marginBottom:10, fontSize:16, fontWeight:900}} className="category-title">Search</h3>
                                
                                <div style={{ border:"1px solid #ccc", padding: 20, borderRadius: 7, paddingBottom: 10}}>
                                    <form  action="/hotel-search" method="GET" className="search-form">
                                        <div className="form-group btn-group" style={{width:'100%'}}>
                                            <select
                                                className="form-control form-select"
                                                name="category"
                                            >
                                                {categories.map((category, i) => ( 
                                                    <option value={category.value} key={i}>{category.label}</option>
                                                ))}
                                            </select>

                                            <button 
                                                className="btn js-search-close" style={{background: "#ffdec8"}}>
                                                <span className="icon bi-search"></span>
                                            </button>
                                            
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="updated">
                                <h4>Do you own or manage a hotel or restaurant?</h4>
                                <p>For restaurant, hotel owners, or anyone who runs a business in the hospitality industry, you can give your business the 
                                    needed online visibility via Kracada. Leverage on our platform to gain visibility and attract new customers.</p>
                                <p> Sign up today to get started.</p>
                                <div className="aside-block">
                                    <a href="/account/register" className="btn">Get Started</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
                        
            <Footer />
        </div>
    );
}
