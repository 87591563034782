import React, { useEffect, useState } from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import Loader from "../../../utils/Loader";
import isLoggedIn from "../../../utils/isLoggedIn";
import { alertError } from "../../../utils/alerts";
import { getCurrentUser, getUserRestaurants } from "../../../utils/actions";
import EmployerLinks from "./components/EmployerLinks";

export default function MyHotels(props) {
    const [loading, setLoading] = useState(true);
    const [hotels, setHotels] = useState([]);
    const TOKEN = localStorage.getItem("kracada:token");
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (!isLoggedIn()) {
            props.history.push("/account/login");
            alertError("Please login to continue");
        } else {
        getCurrentUser(TOKEN)
            .then((res) => {
                setUser(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                alertError(err.response.data.error);
            });
        }
    }, [props.history, TOKEN]);

    useEffect(() => {
        if(user && user._id){
            const data = {id: (user._id && user._id) || ""}
            getUserRestaurants(data)
            .then((res) => {
                if(res.data.success){
                    setHotels(res.data.data);
                }
            })
            .catch((err) => {
                alertError(err?.response?.data?.error);
            });
        }
    }, [user]);
    
    return (
        <div>
            <Header />
            {loading ? (
                <div style={{marginTop: 40}}>
                    <Loader />
                </div>
            ) : (
                <main id="main">
                    <section id="search-result" className="search-result">
                        <div className="container">
                            <div className="row">
                               
                                <div className="col-md-3">
                                    <EmployerLinks accountType={user.accountType && user.accountType} />
                                </div>

                                <div className="col-md-9">
                                    <div className="bg-white trending">
                                        <div className="row col-md-12" style={{ margin: "30px 0" }}>
                                            <div className="text-left mb-3">
                                                <h4 style={{fontSize:16, color:'#f60', float:'right'}} className="font-size-6 font-weight-bold mb-6 text-uppercase">
                                                    <a href="/account/hotels-restaurants"><u>CREATE NEW HOTEL</u></a>
                                                </h4>
                                                <h4 style={{fontSize:16, color:'#f60'}} className="font-size-6 font-weight-bold mb-6 text-uppercase">
                                                    <u> MY HOTELS AND RESTAURANTS</u>
                                                </h4>
                                            </div>
                                            {hotels && hotels.length > 0 ?  <> {
                                                hotels && hotels.map((restaurant, i) => (
                                                    <div className="col-md-6" key={i}>
                                                        <div className="home-restaurant" style={{paddingTop:0, paddingBottom:0,}}>
                                                            <div className="divs">
                                                                <div className="row">
                                                                    <div className="col-5">
                                                                        <div 
                                                                            className="hotel-right" 
                                                                            style={{backgroundImage: `url(${restaurant.banner})`}}>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <div className="">
                                                                            <h6 className="card-title">{restaurant.restaurant_name}</h6>
                                                                            <div className="stars">
                                                                                <i className="bi bi-star"></i>
                                                                                <i className="bi bi-star"></i>
                                                                                <i className="bi bi-star"></i>
                                                                                <i className="bi bi-star"></i>
                                                                                <i className="bi bi-star"></i>
                                                                            </div>
                                                                            <small>
                                                                                <i className="bi bi-h-square"></i> {restaurant.restaurant_category}
                                                                            </small>
                                                                            <small>
                                                                                <i className="bi bi-compass"></i> {restaurant.restaurant_address}
                                                                            </small>
                                                                            <span className="btn btn-warning btn-sm order-btn">
                                                                                {restaurant.status}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            </> : <div className="alert alert-danger mt-4 font-weight-bold">You have uploaded a hotel or restaurant.</div>  }
                                        </div>
                                    </div>
                                </div> 
                            </div>        
                        </div>
                    </section>
                </main>
            )}
            <Footer />
        </div>
    );
}
