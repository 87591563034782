import React from "react";
import Header from "./Header";
import Footer from "./Footer";

export default function PrivacyPolicy() {
    return (
        <>
            <Header />
                <main id="main">
            

                </main>

            <Footer />
        </>
    );
}
