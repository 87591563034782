import React from "react";
import continental from "../../../assets/img/continental.jpg";
import continental2 from "../../../assets/img/continental2.jpg";
import eko from "../../../assets/img/eko.jpg";

function HotelsHighlight() {
    return (
        <section  className="home-restaurant">
            <div className="container">
                <div className="row col-lg-12 mx-auto mb-5">
                    <header className="heading-line2 text-center">
                        <h4 className="title-section text-uppercase">Hotels Highlight</h4>
                    </header>
                </div>

                <div className="row">
                    <div className="col-md-5 mb-3">
                        <div 
                            className="hotel-left"
                            style={{backgroundImage: `url(${continental})`}}>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="divs">
                            <div className="row">
                                <div className="col-5">
                                    <div className="hotel-right" style={{backgroundImage: `url(${continental2})`}}></div>
                                </div>
                                <div className="col-7">
                                    <div className="">
                                        <h6 className="card-title">Lagos Continental Hotel</h6>
                                        <div className="stars">
                                            <i className="bi bi-star"></i>
                                            <i className="bi bi-star"></i>
                                            <i className="bi bi-star"></i>
                                            <i className="bi bi-star"></i>
                                            <i className="bi bi-star"></i>
                                        </div>
                                        <small>
                                            <i className="bi bi-h-square"></i> Hotel
                                        </small>
                                        <small>
                                            <i className="bi bi-compass"></i> Plot 52A Kofo Abayomi Street, Victoria Island, Lagos
                                        </small>
                                        <a
                                            href="https://www.thelagoscontinental.com/"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="btn btn-warning btn-sm order-btn"
                                        >
                                            <i className="fa fa-caret-right" style={{color:"#fff"}}></i> Visit Website
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="divs">
                            <div className="row">
                                <div className="col-5">
                                    <div className="hotel-right" style={{backgroundImage: `url(${eko})`}}></div>
                                </div>
                                <div className="col-7">
                                    <div className="">
                                        <h6 className="card-title">Eko Hotels & Suites</h6>
                                        <div className="stars">
                                            <i className="bi bi-star"></i>
                                            <i className="bi bi-star"></i>
                                            <i className="bi bi-star"></i>
                                            <i className="bi bi-star"></i>
                                            <i className="bi bi-star"></i>
                                        </div>
                                        <small>
                                            <i className="bi bi-h-square"></i> Hotel
                                        </small>
                                        <small>
                                            <i className="bi bi-compass"></i> 1415 Adetokunbo Ademola Street, Lagos
                                        </small>
                                        <a
                                            href="https://ekohotels.com/"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="btn btn-warning btn-sm order-btn"
                                        >
                                            <i className="fa fa-caret-right" style={{color:"#fff"}}></i> Visit Website
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HotelsHighlight;