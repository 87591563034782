import React from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import ArticlesListing from "./ArticlesListing";

export default function ArticlesLanding() {
    return (
        <div>
            <Header />
            <ArticlesListing />
            <Footer />
        </div>
    );
}
