import React, { useEffect, useState } from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import Loader from "../../../utils/Loader";
import isLoggedIn from "../../../utils/isLoggedIn";
import { alertError, alertSuccess } from "../../../utils/alerts";
import { getCurrentUser, createJob } from "../../../utils/actions";
import EmployerLinks from "./components/EmployerLinks";
import locations from "../../../utils/states";


export default function JobAd(props) {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const TOKEN = localStorage.getItem("kracada:token");

    useEffect(() => {
        if (!isLoggedIn()) {
            props.history.push("/account/login");
            alertError("Please login to continue");
        } else {
        getCurrentUser(TOKEN)
            .then((res) => {
                setUser(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                alertError(err.response.data.error);
            });
        }
    }, [props.history, TOKEN]);

    useEffect(() => {
        setTimeout(() => {
            if(user && user.accountType !== "employer"){
                props.history.push("/account/profile");
            }
        }, 5000);
    }, [user, props]);
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        if(user && user.accountType !== undefined && user.accountType === "employer"){
            
            const data = {
                job_title: e.target.job_title.value,
                location: e.target.location.value,
                remuneration: e.target.remuneration.value,
                experience_length: e.target.experience_length.value,
                experience_level: e.target.experience_level.value,
                job_summary: e.target.job_summary.value,
                job_requirements: e.target.job_requirements.value,
                job_responsibilities: e.target.job_requirements.value,
                job_type: e.target.job_type.value,
                job_industry: e.target.job_industry.value,
                min_qualification: e.target.min_qualification.value,
                email_notification: e.target.email_notification.value,
                no_of_days: e.target.no_of_days.value,
                gender: "all",
                isKR:"no",
                posted_by: user._id
            };            
            setLoading(false);
            createJob(data)
                .then((res) => {
                    alertSuccess(res.data.message);
                })
                .catch((err) => {
                    console.log(err.response.data.error)
                    setLoading(false);
                    alertError(err.response.data.error);
                });
        }
    };

    return (
        <div>
            <Header />
            {loading ? (
                <div style={{marginTop: 40}}>
                    <Loader />
                </div>
            ) : (
                <main id="main">
                    <section id="search-result" className="search-result">
                        <div className="container">
                            <div className="row">
                               
                                <div className="col-md-3">
                                    <EmployerLinks accountType={user.accountType && user.accountType} />
                                </div>

                                <div className="col-md-9 col-sm-12">
                                    <div className="bg-white trending mt-0" style={{padding: 20}}>
                                        <form className="col-md-12 padding-small" onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-md-12 form-group square-72">
                                                    <h4 style={{fontSize:16, color:'#f60'}} className="font-size-6 font-weight-bold mb-6 text-uppercase">
                                                        <u> Post Job Advert</u>
                                                    </h4>
                                                    <div className="form-row">
                                                        <div className="col-md-6 form-group">
                                                            <label className="font-weight-bold">Job Title: <span>*</span></label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Job Title"
                                                                name="job_title"
                                                                required 
                                                            />
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <label className="font-weight-bold">Location <span>*</span></label>
                                                            <select className="form-control" id="location" name="location" required >
                                                                <option value="">Select State...</option>
                                                                {locations.map((location) => (
                                                                    <option
                                                                        key={location.value}
                                                                        value={location.value}
                                                                    >
                                                                        {location.label}
                                                                    </option>
                                                                ))}
                                                                </select>
                                                        </div>

                                                        <div className="col-md-6 form-group">
                                                            <label className="font-weight-bold">Work Type <span>*</span></label>
                                                            <select className="form-control" id="job_type" name="job_type" >
                                                                <option value="">Select...</option>
                                                                <option value="full-time">Full-Time</option>
                                                                <option value="part-time">Part-Time</option>
                                                                <option value="internship">Internship</option>
                                                                <option value="contract">Contract</option>
                                                            </select>
                                                        </div>

                                                        <div className="col-md-6 form-group">
                                                            <label className="font-weight-bold">Industry<span>*</span></label>
                                                            <select className="form-control" id="job_industry" name="job_industry" required >
                                                                <option value="">Select...</option>
                                                                <option value="accounting">Accounting</option>
                                                                <option value="administrative-and-office">Administrative and Office</option>
                                                                <option value="agriculture-fishing-forestry">Agriculture, Fishing & Forestry</option>
                                                                <option value="automotive-aviation">Automotive & Aviation</option>
                                                                <option value="advertising-media-communications">Advertising, Media & Communications</option>
                                                                <option value="banking-finance-insurance">Banking, Finance & Insurance</option>
                                                                <option value="construction">Construction</option>
                                                                <option value="creative-and-design">Creative and Design</option>
                                                                <option value="customer-service-and-support">Customer Service and Support</option>
                                                                <option value="education">Education</option>
                                                                <option value="enforcement-security">Enforcement & Security</option>
                                                                <option value="engineering-and-construction">Engineering and Construction</option>
                                                                <option value="health-and-safety">Health and Safety</option>
                                                                <option value="human-resources">Human Resources</option>
                                                                <option value="energy-utilities">Energy & Utilities</option>
                                                                <option value="entertainment-events-sport">Entertainment, Events & Sport</option>
                                                                <option value="healthcare">Healthcare</option>
                                                                <option value="hospitality-hotel">Hospitality & Hotel</option>
                                                                <option value="it-telecoms">IT & Telecoms</option>
                                                                <option value="law-compliance">Law & Compliance</option>
                                                                <option value="manufacturing-warehousing">Manufacturing & Warehousing</option>
                                                                <option value="mining-energy-metals">Mining, Energy & Metals</option>
                                                                <option value="ngo-npo-charity">NGO, NPO & Charity</option>
                                                                <option value="real-estate">Real Estate</option>
                                                                <option value="recruitment">Recruitment</option>
                                                                <option value="retail-fashion-fmcg">Retail, Fashion & FMCG</option>
                                                                <option value="shipping-logistics">Shipping & Logistics</option>
                                                                <option value="tourism-travel">Tourism & Travel</option>
                                                                <option value="unspecified">Unspecified</option>
                                                            </select>
                                                        </div>

                                                        <div className="col-md-6 form-group">
                                                            <label className="font-weight-bold">Minimum Qualification<span>*</span></label>
                                                            <select className="form-control" id="min_qualification" name="min_qualification" required >
                                                                <option value="">Select...</option>
                                                                <option value="ssce">School Certificate (SSCE)</option>
                                                                <option value="diploma">National Diploma (OND/HND)</option>
                                                                <option value="bachelors">Bachelors Degree</option>
                                                                <option value="masters">Masters</option>
                                                                <option value="phd">PhD</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <label className="font-weight-bold">Remuneration: <span>*</span></label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Remuneration"
                                                                name="remuneration"
                                                                required 
                                                            />
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <label className="font-weight-bold">Job Summary: <span>*</span></label>
                                                            <textarea
                                                                cols="33"
                                                                rows="5"
                                                                className="form-control"
                                                                placeholder="Job Summary"
                                                                name="job_summary"
                                                                required 
                                                            ></textarea>
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <label className="font-weight-bold">Job Requirements: <span>*</span></label>
                                                            <textarea
                                                                cols="33"
                                                                rows="5"
                                                                className="form-control"
                                                                placeholder="Job Requirements"
                                                                name="job_requirements"
                                                                required
                                                            ></textarea>
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <label className="font-weight-bold">Experience Level<span>*</span></label>
                                                            <select className="form-control" id="experience_level" name="experience_level" required>
                                                                <option value="">Select...</option>
                                                                <option value="entry-level">Entry Level</option>
                                                                <option value="mid-level">Mid Level</option>
                                                                <option value="senior-level">Senior Level</option>
                                                                <option value="executive-management">Executive Management</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <label className="font-weight-bold">Experience Length (in years)<span>*</span></label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                placeholder="Experience Length (in years)"
                                                                name="experience_length"
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="form-row">
                                                        <div className="col-md-6 form-group">
                                                            <label className="font-weight-bold">Notifications Email: <span>*</span></label>
                                                            <input
                                                                type="email"
                                                                className="form-control"
                                                                placeholder="Application Notifications should go to this email"
                                                                name="email_notification"
                                                                required 
                                                            />
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <label className="font-weight-bold">Ad Duration: <span>*</span></label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                placeholder="How many days should the job post run"
                                                                name="no_of_days"
                                                                required 
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" className="btn btn-dark">Post Job</button>
                                            </div>
                                        </form>
                                    </div>
                                </div> 
                            </div>        
                        </div>
                    </section>
                </main>
            )}
            <Footer />
        </div>
    );
}
