import React, { useEffect, useState } from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import Loader from "../../../utils/Loader";
import { getTourismByCity } from "../../../utils/actions";
import { ucWords } from "../../../utils/strings";

// import nexttrip from "../../../assets/img/nexttrip.jpg";

import london from "../../../assets/img/london.jpg";
// import amsterdam from "../../../assets/img/amsterdam.jpg";
// import dubai from "../../../assets/img/dubai.jpg";

// import newyork from "../../../assets/img/newyork.jpg";
// import paris from "../../../assets/img/paris.jpg";
// import barcelona from "../../../assets/img/barcelona.jpg";

// import tenerife from "../../../assets/img/tenerife.jpg";
// import dublin from "../../../assets/img/dublin.jpg";
// import rome from "../../../assets/img/rome.jpg";

// import accra from "../../../assets/img/accra.webp";
// import joburg from "../../../assets/img/joburg.webp";
// import lagos from "../../../assets/img/lagos.webp";

// import abuja from "../../../assets/img/abuja.webp";
// import capetown from "../../../assets/img/capetown.webp";
// import edinburg from "../../../assets/img/edinburg.jpg";

// import radissonblue1 from "../../../assets/img/radissonblue1.webp";
// import jobsImage from "../../../assets/img/post-portrait-7.jpg";


export default function Travel(props) {
    let { id } = props.match.params;
    const [loading, setLoading] = useState(true);
    const [tourism, setTourism] = useState([]);

    useEffect(() => {
        if(id){
            getTourismByCity(id)
            .then((res) => {
                setTourism(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err.response.data);
            })
        }
    }, [id]); 

    console.log(tourism)

    return (
        <div>
            <Header />
            {loading ? (
                <Loader />
            ) : (
                <>
                    
                    <section className="tourism-hotels" style={{marginTop: 90}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div 
                                        className="tourist-city-image" 
                                        style={{
                                            borderRadius: 9, 
                                            backgroundImage: `url(${london})`, 
                                            backgroundSize: 'cover', 
                                            backgroundPosition: 'center',
                                    }}>
                                        <div className="layer">
                                            <div className="inner-tourist">
                                                <h3>{ucWords(id)}</h3>
                                                <p>Netherlands</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>

                        </div>
                    </section>

                    <section className="three-highlights" style={{marginTop: 80}}>
                        <div className="container">
                            <div className="row">
                                
                            </div> 
                        </div>
                    </section> 
                
                </>
                
            )}
            <Footer />
        </div>
    );
}
