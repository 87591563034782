import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import Header from "../../Header";
import Footer from "../../Footer";
import Loader from "../../../utils/Loader";
import isLoggedIn from "../../../utils/isLoggedIn";
import { alertError } from "../../../utils/alerts";
import { getCurrentUser, getMyJobs } from "../../../utils/actions";
import { substrwords } from "../../../utils/strings";
import EmployerLinks from "./components/EmployerLinks";
import amountWithCommas from "../../../utils/amountFormmater";

export default function MyJobs(props) {
    const [loading, setLoading] = useState(true);
    const [jobs, setJobs] = useState([]);
    const TOKEN = localStorage.getItem("kracada:token");
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (!isLoggedIn()) {
            props.history.push("/account/login");
            alertError("Please login to continue");
        } else {
        getCurrentUser(TOKEN)
            .then((res) => {
                setUser(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                alertError(err.response.data.error);
            });
        }
    }, [props.history, TOKEN]);

    useEffect(() => {
        if(user && user._id){
            const data = {id: (user._id && user._id) || ""}
            getMyJobs(data)
            .then((res) => {
                if(res.data.success){
                    setJobs(res.data.data);
                }
            })
            .catch((err) => {
                alertError(err?.response?.data?.error);
            });
        }
    }, [user]);
    
    return (
        <div>
            <Header />
            {loading ? (
                <div style={{marginTop: 40}}>
                    <Loader />
                </div>
            ) : (
                <main id="main">
                    <section id="search-result" className="search-result">
                        <div className="container">
                            <div className="row">
                               
                                <div className="col-md-3">
                                    <EmployerLinks accountType={user.accountType && user.accountType} />
                                </div>

                                <div className="col-md-9">
                                    {(user && user.accountType && user.accountType === "employer") ? <>
                                        <div className="bg-white trending">
                                            <div className="row col-md-12" style={{ margin: "30px 0" }}>
                                                <div className="text-left mb-3">
                                                    <h4 style={{fontSize:16, color:'#f60'}} className="font-size-6 font-weight-bold mb-6 text-uppercase">
                                                        <u> MY JOBS</u>
                                                    </h4>
                                                </div>
                                                {jobs && jobs.length > 0 ?  <> {
                                                    jobs && jobs.map((job, i) => (
                                                        <div className="col-md-6 col-sm-12 col-xs-12" key={i}>
                                                            <a href={`myjob/${job._id}`} >
                                                                <div className="d-md-flex post-entry-2 small-img jobs-card" style={{position:'relative'}}>
                                                                    <div>
                                                                        <h3 className="job-title">{job.job_title && job.job_title} {"  "} 
                                                                            <span className="date">{job.location ? job.location : null} </span>
                                                                        </h3>
                                                                        <div className="post-meta">
                                                                            <span>NGN {job.remuneration && amountWithCommas(job.remuneration)} </span>
                                                                        </div>
                                                                        <p>{(job.job_summary && job.job_summary) ? parse(substrwords(job.job_summary,100)) : null} </p>

                                                                        <span className="date" 
                                                                            style={ job.status === "Pending" ? { 
                                                                                position:'absolute', color:'#fff', bottom:0, right:10, backgroundColor:'#f60'} : { position:'absolute', color:'#fff', bottom:0, right:10, backgroundColor:'#093'}} >
                                                                            {job.status ? job.status : null} 
                                                                        </span> 

                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    ))
                                                }
                                                </> : null }

                                            </div>
                                        </div>
                                    </> : null}
                                </div> 
                            </div>        
                        </div>
                    </section>
                </main>
            )}
            <Footer />
        </div>
    );
}
