import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import Header from "../../Header";
import Footer from "../../Footer";
import Loader from "../../../utils/Loader";
import europeschools from "../../../assets/img/europe-schools.png";

const INIT_STATE = {
    title:"Top Ten Tips on Gaining Admission to Ivy League Universities ",
    content: "<p>Here are the top ten tips on gaining admission to Ivy League universities:</p><p><strong>1. Academic Excellence:</strong> Maintain a high GPA (3.5 or above) and challenging coursework, such as Advanced Placement or International Baccalaureate classes.</p><p><strong>2. Standardized Tests:</strong> Score high on the SAT or ACT (above 1400 or 32, respectively) and prepare for SAT Subject Tests or other exams.</p><p><strong>3. Extracurricular Activities:</strong> Engage in meaningful activities outside the classroom, such as sports, clubs, or volunteer work, and demonstrate leadership and commitment.</p><p><strong>4. Personal Statement:</strong> Craft a compelling and authentic college essay that showcases your voice, passions, and goals.</p><p><strong>5. Letters of Recommendation:</strong> Build strong relationships with teachers, mentors, or supervisors who can write supportive and insightful recommendations.</p><p><strong>6. Interviews:</strong> Prepare for and ace alumni or admissions interviews by demonstrating confidence, curiosity, and knowledge about the university.</p><p><strong>7. Community Service:</strong> Show a commitment to giving back through volunteer work, social entrepreneurship, or activism.</p><p><strong>8. Summer Programs:</strong> Participate in prestigious summer programs, internships, or research opportunities to demonstrate academic and professional potential.</p><p><strong>9. Networking:</strong> Connect with current students, alumni, or professors to learn more about the university and demonstrate interest.</p><p><strong>10. Passion and Authenticity:</strong> Pursue your genuine interests and showcase your unique perspective, talents, and character throughout the application process.</p><p>Remember, admission to Ivy League universities is highly competitive, so it's essential to stand out while remaining true to yourself. Good luck!</p>",
};

export default function Article1() {
    const [loading, setLoading] = useState(true);
    const [state] = useState(INIT_STATE);
    
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1200);
    }, []); 
    
    return (
        <div>
            <Header />
                {loading ? (
                    <Loader />
                ) : (
                    <section className="single-post-content" style={{marginTop: 90, backgroundColor: '#fff'}}>
                        <div className="container">
                            <div className="row g-5">
                                <div className="col-md-12 post-content mt-4">

                                    <div className="single-post mt-4">
                                        <h1 className="mb-5">{state.title && parse(state.title)} </h1>
                                        
                                        <div className="my-4" style={{backgroundColor: '#999', width:'100%' }}>
                                            <img 
                                                src={europeschools} 
                                                alt="news" 
                                                className="img-fluid" 
                                                style={{
                                                    width: '100%', height:'100%'
                                                }} 
                                            />
                                        </div>
                                        
                                        <div style={{lineHeight: 1.9, textAlign: 'justify', fontSize: 19}}>
                                            {parse(state.content)}
                                        </div>
                                    </div>
                                </div> 

                            </div>
                        </div>
                    </section>
                )}
            <Footer />
        </div>
    );
}
