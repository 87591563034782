import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import Header from "../../Header";
import Footer from "../../Footer";
import Loader from "../../../utils/Loader";
import studyineurope from "../../../assets/img/study-in-europe.jpg";

const INIT_STATE = {
    title:"6 European Countries That Do Not Require IELTS",
    content: "<p>Here are six European countries where you can study without IELTS:</p><p><strong>- Germany:</strong> The country is home to some of the world’s most affordable public universities. The University of Koblenz and Landau, Braunschweig University of Technology and University of Giessen are some of the German universities that do not require an IELTS score.</p><p><strong>- Ireland:</strong> Ireland is one of the best countries to study without IELTS. The University of Limerick, Royal College of Surgeons in Ireland, Maynooth University and Dublin Institute of Technology are some of the universities in Ireland that do not require IELTS.</p><p><strong>- France:</strong> France is a dream destination for many students. Some universities in France that do not require IELTS are American Business School, Paris, ESC Rennes School of Business and ESAIP School of Engineers.</p><p><strong>- Spain:</strong> The country is known for its rich culture and history. Universities in Spain that do not require IELTS include the University of Valencia, Pablo de Olavide University and Universidad Antonio de Nebrija.</p><p><strong>- Poland:</strong> Poland is known for its cultural richness and modernity. Universities in Poland that do not require IELTS include the University of Bialystok, Jagiellonian University and Kozminski University.</p><p><strong>- Italy:</strong> Italy is known for its contributions to the world of fashion, design, film, opera and architecture. Universities in Italy that do not require IELTS include the University of Florence, University of Pisa and University of Padua.</p>",
};

export default function Article2() {
    const [loading, setLoading] = useState(true);
    const [state] = useState(INIT_STATE);
    
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1200);
    }, []); 
    
    return (
        <div>
            <Header />
                {loading ? (
                    <Loader />
                ) : (
                    <section className="single-post-content" style={{marginTop: 90, backgroundColor: '#fff'}}>
                        <div className="container">
                            <div className="row g-5">
                                <div className="col-md-12 post-content mt-4">

                                    <div className="single-post mt-4">
                                        <h1 className="mb-5">{state.title && parse(state.title)} </h1>
                                        
                                        <div className="my-4" style={{backgroundColor: '#999', width:'100%' }}>
                                            <img 
                                                src={studyineurope} 
                                                alt="news" 
                                                className="img-fluid" 
                                                style={{
                                                    width: '100%', height:'100%'
                                                }} 
                                            />
                                        </div>
                                        
                                        <div style={{lineHeight: 1.9, textAlign: 'justify', fontSize: 19}}>
                                            {parse(state.content)}
                                        </div>
                                    </div>
                                </div> 

                            </div>
                        </div>
                    </section>
                )}
            <Footer />
        </div>
    );
}
