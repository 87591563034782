import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import Loader from "../../../utils/Loader";
import { substrwords, ucWords } from "../../../utils/strings";
import { getApprovedKRJobs, getKRApprovedKRJobs } from "../../../utils/actions";

export default function FeaturedJobs() {
    const [loading, setLoading] = useState(true);
    const [jobs, setJobs] = useState([]);
    const [krjobs, setKRJobs] = useState([]);

    useEffect(() => {
        getApprovedKRJobs()
            .then((res) => {
                setJobs(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err.response);
            })
    }, []); 

    useEffect(() => {
        getKRApprovedKRJobs()
            .then((res) => {
                if(res.data.success){
                    setKRJobs(res.data.data);
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err.response);
            })
    }, []); 
    
    return (
        <>
            <section style={{marginTop: 0}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-9">
                            

                            {loading ? (
                                <Loader />
                            ) : (
                                <>
                                <div id="featured">

                                    <h3 className="category-title">Featured Jobs</h3>

                                    <div className="row">
                                        {krjobs && krjobs.map((krjob, i) => (
                                            <div className="col-md-6 col-sm-6 col-xs-12" key={i}>
                                                <a href={`jobs/${krjob._id}`} >
                                                    <div className="d-md-flex post-entry-2 small-img jobs-card" 
                                                    style={{border: "5px solid #FFD9B3"}}>
                                                        <div>
                                                            <h3>{i + 1}. {krjob.job_title && krjob.job_title}  {"  "} {krjob.location ? <span className="date" 
                                                            style={{textTransform:'uppercase'}}>{krjob.location}</span> : null}</h3>
                                                            <div className="post-meta">
                                                                <span style={{marginBottom:5, display:'block',}}>
                                                                    <strong>{ucWords(krjob.job_industry.toLowerCase().replace(/-/g, " "))}</strong> 
                                                                </span>
                                                                <span><strong>{krjob.job_type.toLowerCase().replace(/-/g, " ").trim()}</strong></span>{(krjob.experience_level && krjob.experience_level) ? <>
                                                                <span><strong>, {ucWords(krjob.experience_level.toLowerCase().replace(/-/g, " ").trim())}
                                                                    </strong>  
                                                                </span></>: null}
                                                            </div>
                                                            <p>{krjob.job_summary ? parse(substrwords(krjob.job_summary,100)) : null} </p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                {/* {(krjobs && krjobs.length > 0) ? 
                                    <div style={{height: 3, backgroundColor:'#BCBEB8', width:'100%', marginBottom: 25 }} ></div>
                                : null } */}

                                <div id="top">

                                    <h3 className="category-title mt-4">Top Jobs</h3>

                                    <div className="row">
                                        {jobs && jobs.map((job, i) => (
                                            <div className="col-md-6 col-sm-6 col-xs-12" key={i}>
                                                <a href={`jobs/${job._id}`} >
                                                    <div className="d-md-flex post-entry-2 small-img jobs-card">
                                                        <div>
                                                            <h3>{i + 1}. {job.job_title && ucWords(job.job_title.toLowerCase())}  {"  "} {job.location ? <span className="date">{job.location}</span> : null}</h3>
                                                            <div className="post-meta">
                                                                <span style={{marginBottom:5, display:'block',}}>
                                                                    <strong>{ucWords(job.job_industry.toLowerCase().replace(/-/g, " "))}</strong> 
                                                                </span>
                                                                <span><strong>{job.job_type.toLowerCase().replace(/-/g, " ").trim()}</strong></span>{(job.experience_level && job.experience_level) ? <>
                                                                <span><strong>, {ucWords(job.experience_level.toLowerCase().replace(/-/g, " ").trim())}
                                                                    </strong>  
                                                                </span></>: null}
                                                            </div>
                                                            <p>{job.job_summary ? parse(substrwords(job.job_summary,100)) : null} </p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                </>
                            )}
                        </div> 

                        <div className="col-md-3">

                            <div className="aside-block">
                                <h3  style={{marginBottom:10, fontSize:16, fontWeight:900}} className="category-title">Search Jobs</h3>
                                
                                <div style={{ border:"1px solid #ccc", padding: 20, borderRadius: 7, paddingBottom: 10}}>
                                    <form action="" className="search-form">
                                        <div className="form-group btn-group" style={{width:'100%'}}>

                                            <input type="text" placeholder="Search" className="form-control" />

                                            <button className="btn js-search-close" style={{background: "#ffdec8"}}>
                                                <span className="icon bi-search"></span>
                                            </button>
                                            
                                        </div>
                                    </form>
                                </div>
                            </div>

                            
                            <div className="aside-block">
                                
                                <a href="/account/register" className="btn js-search-close" 
                                    style={{background: "#ffdec8", textAlign:'center', width:'100%', marginTop:0, fontWeight:'bold'}}>
                                    Create Account
                                </a>
                            </div>

                            <div className="trending">
                                <h3 className="trending-title">Articles</h3>
                                <ul className="trending-post">
                                    <li>
                                        <a href="/articles/gaining-admission-to-ivy-league-universities">
                                            <span className="number">1</span>   
                                            <h3>Top Ten Tips on Gaining Admission to Ivy League Universities</h3>
                                            <span className="author"><i className="fa fa-calendar"></i> January 12th, 2024</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/articles/european-countries-that-do-not-require-ielts">
                                            <span className="number">2</span>
                                            <h3>6 European Countries That Do Not Require IELTS</h3>
                                            <span className="author"><i className="fa fa-calendar"></i> February 9th, 2024</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/articles/universities-that-have-waived-application-fee-for-nigerians">
                                            <span className="number">3</span>
                                            <h3>These 9 Universities Have Waived Application Fee For Nigerians</h3>
                                            <span className="author"><i className="fa fa-calendar"></i> March 15th, 2024</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/articles/how-to-relocate-with-your-family-to-the-united-kingdom">
                                            <span className="number">4</span>
                                            <h3>9 Tips on How To Relocate With Your Family To The United Kingdom</h3>
                                            <span className="author"><i className="fa fa-calendar"></i> April 12th, 2024</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            
                        </div>
                    </div>        
                </div>
            </section>
        </>
    );
}
