import React, { useState } from "react";
import Loader from "../../../../utils/Loader";
import { alertError, alertSuccess } from "../../../../utils/alerts";
import { updateExperience } from "../../../../utils/actions";

const industries = [
  { value: "unspecified", label: "Unspecified" },
  { value: "accounting", label: "Accounting" },
  { value: "administrative-and-office", label: "Administrative and Office" },
  { value: "agriculture-fishing-forestry", label: "Agriculture, Fishing & Forestry" },
  { value: "automotive-aviation", label: "Automotive & Aviation" },
  { value: "advertising-media-communications", label: "Advertising, Media & Communications" },
  { value: "banking-finance-insurance", label: "Banking, Finance & Insurance" },
  { value: "construction", label: "Construction" },
  { value: "creative-and-design", label: "Creative and Design" },
  { value: "customer-service-and-support", label: "Customer Service and Support" },
  { value: "education", label: "Education" },
  { value: "enforcement-security", label: "Enforcement & Security" },
  { value: "engineering-and-construction", label: "Engineering and Construction" },
  { value: "health-and-safety", label: "Health and Safety" },
  { value: "human-resources", label: "Human Resources" },
  { value: "energy-utilities", label: "Energy & Utilities" },
  { value: "entertainment-events-sport", label: "Entertainment, Events & Sport" },
  { value: "healthcare", label: "Healthcare" },
  { value: "hospitality-hotel", label: "Hospitality & Hotel" },
  { value: "it-telecoms", label: "IT & Telecoms" },
  { value: "law-compliance", label: "Law & Compliance" },
  { value: "manufacturing-warehousing", label: "Manufacturing & Warehousing" },
  { value: "mining-energy-metals", label: "Mining, Energy & Metals" },
  { value: "ngo-npo-charity", label: "NGO, NPO & Charity" },
  { value: "real-estate", label: "Real Estate" },
  { value: "recruitment", label: "Recruitment" },
  { value: "retail-fashion-fmcg", label: "Retail, Fashion & FMCG" },
  { value: "shipping-logistics", label: "Shipping & Logistics" },
  { value: "tourism-travel", label: "Tourism & Travel" },
];

export default function Experience() {
  const [loading, setLoading] = useState(false);
  const [duties, setDuties] = useState([]);

  function handleChange(i, event) {
    const values = [...duties];
    values[i].value = event.target.value;
    setDuties(values);
  }

  function handleAdd() {
    const values = [...duties];
    values.push({ value: null });
    setDuties(values);
  }

  function handleRemove(i) {
    const values = [...duties];
    values.splice(i, 1);
    setDuties(values);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const job_duties = [];
    for (let i = 0; i < duties.length; i++) {
      job_duties.push(duties[i].value);
    }

    const data = {
      company: e.target.company.value,
      location: e.target.company_location.value,
      start_date: e.target.start_date.value,
      end_date: e.target.end_date.value,
      job_title: e.target.job_title.value,
      industry: e.target.industry.value,
      job_duties: JSON.stringify(job_duties),
    };
    setLoading(true);
    updateExperience(data)
      .then((res) => {
        console.log(res.data)
        
        setLoading(false);
        alertSuccess(res.data.message);
      })
      .catch((err) => {
        setLoading(false);
        alertError(err.response.data.error);
      });
  };

  return (
    <div className="row col-md-12" style={{ margin: "30px 0" }}>
      <div className="text-left" style={{ paddingLeft: "12px" }}>
        <h4 style={{fontSize:16, color:'#f60'}} className="font-size-6 font-weight-bold mb-6 text-uppercase">
            <u> Update Work Experience</u>
        </h4>
      </div>
      <form className="col-md-12 padding-small" onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="col-md-6 form-group">
            <label className="font-weight-bold">Company: <span>*</span></label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter your employer name"
              name="company"
              required 
            />
          </div>
          <div className="col-md-6 form-group">
            <label className="font-weight-bold">Location: <span>*</span></label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter employer location"
              name="company_location"
              required 
            />
          </div>
        </div>

        <div className="form-row">
          <div className="col-md-6 form-group">
            <label className="font-weight-bold">Start Date: <span>*</span></label>
            <input
              type="date"
              className="form-control"
              placeholder="From"
              name="start_date"
              required 
            />
          </div>
          <div className="col-md-6 form-group">
            <label className="font-weight-bold">End Date: <span>*</span> </label>
            <input
              type="date"
              className="form-control"
              placeholder="To"
              name="end_date"
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6 form-group">
            <label className="font-weight-bold">Industry: <span>*</span></label>
            <select required className="form-control" id="industry" name="industry">
              <option value="">Select Industry</option>
              {industries.map((industry) => (
                <option key={industry.value} value={industry.value}>{industry.label}</option>
              ))}
            </select>
          </div>
          <div className="col-md-6 form-group">
            <label className="font-weight-bold">Job Title: <span>*</span></label>
            <input
              type="text"
              required 
              className="form-control"
              placeholder="Enter your job title"
              name="job_title"
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-12 form-group">
            <label className="font-weight-bold"> Responsibilities / Achievements: <span>*</span></label>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-12 form-group">
            {duties.map((duty, idx) => {
              return (
                <div className="row mb-2" key={`${duty}-${idx}`}>
                  <div className="col-lg-12">
                    <input
                      id="job_duties"
                      type="text"
                      name="job_duties"
                      className="form-control"
                      value={duty.value || ""}
                      placeholder={duty.value}
                      onChange={(e) => handleChange(idx, e)}
                    />
                    <small
                      className="ml-1 mr-3 text-danger "
                      onClick={() => handleRemove(idx)}
                    >
                      <u>Remove</u>
                    </small>
                  </div>
                </div>
              );
            })}
            <button
              type="button"
              color="primary"
              className="btn btn-primary"
              onClick={() => handleAdd()}
            >
              <i className="fas fa-plus-circle"></i> Add
            </button>
          </div>
        </div>

        <div className="form-group">
          {loading ? (
            // <button type="button" disabled={true} className="btn btn-dark">
              <Loader />
            // </button>
          ) : (
            <button type="submit" className="btn btn-dark btn-block">
              Save Changes
            </button>
          )}
        </div>
      </form>
    </div>
  );
}
